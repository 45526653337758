@use "core/src/stylesheets/framework/modules" as module;
@use "@angular/material/core/theming/inspection" as inspection;

jbd-ui-button-secondary button.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: transparent;
  --mdc-outlined-button-label-text-color: #{inspection.get-theme-color(
      module.$light-theme,
      primary,
      100-contrast
    )} !important;
  background-color: inspection.get-theme-color(
    module.$light-theme,
    primary,
    100
  );

  &::before {
    border-color: rgba(
      inspection.get-theme-color(module.$light-theme, primary, default),
      0.5
    ) !important;
  }
}

// disabled styles

jbd-ui-button-primary .mat-mdc-unelevated-button.mat-primary[disabled] {
  --mdc-filled-button-container-color: #{inspection.get-theme-color(
      module.$light-theme,
      primary,
      default
    )} !important;
  --mdc-filled-button-disabled-container-color: var(
    --mdc-filled-button-container-color
  );
  --mdc-filled-button-label-text-color: #{inspection.get-theme-color(
      module.$light-theme,
      primary,
      default-contrast
    )} !important;

  --mdc-filled-button-disabled-label-text-color: var(
    --mdc-filled-button-label-text-color
  );
}

jbd-ui-button-secondary .mat-mdc-outlined-button.mat-primary[disabled] {
  --mdc-filled-button-label-text-color: #{inspection.get-theme-color(
      module.$light-theme,
      primary,
      default
    )};
}

// spinner styles

jbd-ui-button-secondary,
jbd-ui-button-primary {
  jbd-ui-button-inner mat-progress-spinner.mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #{inspection.get-theme-color(
        module.$light-theme,
        primary,
        default-contrast
      )};
  }
}
