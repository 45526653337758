@use "base/colors" as partner-colors;

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  .mat-mdc-tab-list {
    .mdc-tab-indicator
      .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
      border-top-color: map-get(partner-colors.$primary, 800);
    }
  }
}
