@use "variables" as calVar;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

jbd-app-company-location-booking-overview-calendar-widget
  full-calendar.fc.fc-theme-standard {
  .fc-list-empty {
    border: var.$border-width-default solid var(--fc-border-color);
    border-radius: var.$border-radius-sm;
    background-color: map-get(color.$monochrome, "white");

    .fc-list-empty-cushion {
      margin: 0;
    }
  }
}
