@import "normalize.css/normalize.css";
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("/assets/fonts/open-sans/open-sans-v26-latin-regular.woff2") format("woff2"), url("/assets/fonts/open-sans/open-sans-v26-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-500 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("/assets/fonts/open-sans/open-sans-v26-latin-500.woff2") format("woff2"), url("/assets/fonts/open-sans/open-sans-v26-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("/assets/fonts/open-sans/open-sans-v26-latin-600.woff2") format("woff2"), url("/assets/fonts/open-sans/open-sans-v26-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("/assets/fonts/open-sans/open-sans-v26-latin-700.woff2") format("woff2"), url("/assets/fonts/open-sans/open-sans-v26-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("/assets/fonts/open-sans/open-sans-v26-latin-800.woff2") format("woff2"), url("/assets/fonts/open-sans/open-sans-v26-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@keyframes animate-pb-0-to-24 {
  from {
    padding-bottom: 0;
  }
  to {
    padding-bottom: 24px;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bg-monochrome-white {
  background-color: #fff !important;
}

.bg-monochrome-100 {
  background-color: #f5f7f7 !important;
}

.bg-monochrome-200 {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.bg-monochrome-300 {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.bg-monochrome-400 {
  background-color: rgba(0, 0, 0, 0.16) !important;
}

.bg-danger-50 {
  background-color: #fef2f2 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-accent-600 {
  background-color: #00aa9e !important;
}

.border-0 {
  border: none !important;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid !important;
}

.border {
  border-width: 2px !important;
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-bottom {
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
}

.border-right {
  border-right-width: 2px !important;
  border-right-style: solid !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.border-radius {
  border-radius: 8px !important;
}

.border-radius-sm {
  border-radius: 4px !important;
}

.border-radius-lg {
  border-radius: 16px !important;
}

.border-color-100 {
  border-color: #f5f7f7 !important;
}

.border-color-200 {
  border-color: rgba(0, 0, 0, 0.04) !important;
}

.border-color-300 {
  border-color: rgba(0, 0, 0, 0.08) !important;
}

.border-color-400 {
  border-color: rgba(0, 0, 0, 0.16) !important;
}

.border-color-500 {
  border-color: rgba(0, 0, 0, 0.3) !important;
}

.border-color-transparent {
  border-color: transparent !important;
}

.border-color-danger-600 {
  border-color: #dc2626 !important;
}

.is-circle {
  border-radius: 50% !important;
}

.none {
  display: none !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.inline-flex {
  display: inline-flex !important;
}

.campaign-image-aspect-ratio {
  aspect-ratio: 768/1065;
}

.overflow-hidden {
  overflow: hidden !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

/**
generates spacing-helper-classes like
following this schema:

[p|m|nm][""|t|b|l|r|x|y]-[""|sm|md|lg|xl]-[0|4|8|12|16|20|24|32|48|64]

e.g
m-0 => margin: 0
pt-sm-12 => padding-top: 12px on devices where min-width is matching the sm-breakpoint
or
py-12 => padding-top: 12px and padding-bottom: 12px
or
nmy-12 => negative margin-top: 12px and negative margin-bottom: 12px
**/
.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.m-4 {
  margin: 4px !important;
}

.p-4 {
  padding: 4px !important;
}

.nm-4 {
  margin: -4px !important;
}

.m-8 {
  margin: 8px !important;
}

.p-8 {
  padding: 8px !important;
}

.nm-8 {
  margin: -8px !important;
}

.m-12 {
  margin: 12px !important;
}

.p-12 {
  padding: 12px !important;
}

.nm-12 {
  margin: -12px !important;
}

.m-16 {
  margin: 16px !important;
}

.p-16 {
  padding: 16px !important;
}

.nm-16 {
  margin: -16px !important;
}

.m-20 {
  margin: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.nm-20 {
  margin: -20px !important;
}

.m-24 {
  margin: 24px !important;
}

.p-24 {
  padding: 24px !important;
}

.nm-24 {
  margin: -24px !important;
}

.m-32 {
  margin: 32px !important;
}

.p-32 {
  padding: 32px !important;
}

.nm-32 {
  margin: -32px !important;
}

.m-48 {
  margin: 48px !important;
}

.p-48 {
  padding: 48px !important;
}

.nm-48 {
  margin: -48px !important;
}

.m-64 {
  margin: 64px !important;
}

.p-64 {
  padding: 64px !important;
}

.nm-64 {
  margin: -64px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.nmt-4 {
  margin-top: -4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.nmt-8 {
  margin-top: -8px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.nmt-12 {
  margin-top: -12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.nmt-16 {
  margin-top: -16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.nmt-20 {
  margin-top: -20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.nmt-24 {
  margin-top: -24px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.nmt-32 {
  margin-top: -32px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.nmt-48 {
  margin-top: -48px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.pt-64 {
  padding-top: 64px !important;
}

.nmt-64 {
  margin-top: -64px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.nmb-4 {
  margin-bottom: -4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.nmb-8 {
  margin-bottom: -8px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.nmb-12 {
  margin-bottom: -12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.nmb-16 {
  margin-bottom: -16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.nmb-20 {
  margin-bottom: -20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.nmb-24 {
  margin-bottom: -24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.nmb-32 {
  margin-bottom: -32px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.nmb-48 {
  margin-bottom: -48px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.pb-64 {
  padding-bottom: 64px !important;
}

.nmb-64 {
  margin-bottom: -64px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.nml-4 {
  margin-left: -4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.nml-8 {
  margin-left: -8px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.nml-12 {
  margin-left: -12px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.nml-16 {
  margin-left: -16px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.nml-20 {
  margin-left: -20px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.nml-24 {
  margin-left: -24px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.nml-32 {
  margin-left: -32px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.nml-48 {
  margin-left: -48px !important;
}

.ml-64 {
  margin-left: 64px !important;
}

.pl-64 {
  padding-left: 64px !important;
}

.nml-64 {
  margin-left: -64px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.nmr-4 {
  margin-right: -4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.nmr-8 {
  margin-right: -8px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.nmr-12 {
  margin-right: -12px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.nmr-16 {
  margin-right: -16px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.nmr-20 {
  margin-right: -20px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.nmr-24 {
  margin-right: -24px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.nmr-32 {
  margin-right: -32px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.nmr-48 {
  margin-right: -48px !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.pr-64 {
  padding-right: 64px !important;
}

.nmr-64 {
  margin-right: -64px !important;
}

.mx-auto {
  margin-inline: auto !important;
}

.mx-0 {
  margin-inline: 0px !important;
}

.px-0 {
  padding-inline: 0px !important;
}

.mx-4 {
  margin-inline: 4px !important;
}

.px-4 {
  padding-inline: 4px !important;
}

.nmx-4 {
  margin-inline: -4px !important;
}

.mx-8 {
  margin-inline: 8px !important;
}

.px-8 {
  padding-inline: 8px !important;
}

.nmx-8 {
  margin-inline: -8px !important;
}

.mx-12 {
  margin-inline: 12px !important;
}

.px-12 {
  padding-inline: 12px !important;
}

.nmx-12 {
  margin-inline: -12px !important;
}

.mx-16 {
  margin-inline: 16px !important;
}

.px-16 {
  padding-inline: 16px !important;
}

.nmx-16 {
  margin-inline: -16px !important;
}

.mx-20 {
  margin-inline: 20px !important;
}

.px-20 {
  padding-inline: 20px !important;
}

.nmx-20 {
  margin-inline: -20px !important;
}

.mx-24 {
  margin-inline: 24px !important;
}

.px-24 {
  padding-inline: 24px !important;
}

.nmx-24 {
  margin-inline: -24px !important;
}

.mx-32 {
  margin-inline: 32px !important;
}

.px-32 {
  padding-inline: 32px !important;
}

.nmx-32 {
  margin-inline: -32px !important;
}

.mx-48 {
  margin-inline: 48px !important;
}

.px-48 {
  padding-inline: 48px !important;
}

.nmx-48 {
  margin-inline: -48px !important;
}

.mx-64 {
  margin-inline: 64px !important;
}

.px-64 {
  padding-inline: 64px !important;
}

.nmx-64 {
  margin-inline: -64px !important;
}

.my-auto {
  margin-block: auto !important;
}

.my-0 {
  margin-block: 0px !important;
}

.py-0 {
  padding-block: 0px !important;
}

.my-4 {
  margin-block: 4px !important;
}

.py-4 {
  padding-block: 4px !important;
}

.nmy-4 {
  margin-block: -4px !important;
}

.my-8 {
  margin-block: 8px !important;
}

.py-8 {
  padding-block: 8px !important;
}

.nmy-8 {
  margin-block: -8px !important;
}

.my-12 {
  margin-block: 12px !important;
}

.py-12 {
  padding-block: 12px !important;
}

.nmy-12 {
  margin-block: -12px !important;
}

.my-16 {
  margin-block: 16px !important;
}

.py-16 {
  padding-block: 16px !important;
}

.nmy-16 {
  margin-block: -16px !important;
}

.my-20 {
  margin-block: 20px !important;
}

.py-20 {
  padding-block: 20px !important;
}

.nmy-20 {
  margin-block: -20px !important;
}

.my-24 {
  margin-block: 24px !important;
}

.py-24 {
  padding-block: 24px !important;
}

.nmy-24 {
  margin-block: -24px !important;
}

.my-32 {
  margin-block: 32px !important;
}

.py-32 {
  padding-block: 32px !important;
}

.nmy-32 {
  margin-block: -32px !important;
}

.my-48 {
  margin-block: 48px !important;
}

.py-48 {
  padding-block: 48px !important;
}

.nmy-48 {
  margin-block: -48px !important;
}

.my-64 {
  margin-block: 64px !important;
}

.py-64 {
  padding-block: 64px !important;
}

.nmy-64 {
  margin-block: -64px !important;
}

@media (min-width: 600px) {
  .m-sm-auto {
    margin: auto !important;
  }
}
@media (min-width: 600px) {
  .m-sm-0 {
    margin: 0px !important;
  }
  .p-sm-0 {
    padding: 0px !important;
  }
}
@media (min-width: 600px) {
  .m-sm-4 {
    margin: 4px !important;
  }
  .p-sm-4 {
    padding: 4px !important;
  }
  .nm-sm-4 {
    margin: -4px !important;
  }
}
@media (min-width: 600px) {
  .m-sm-8 {
    margin: 8px !important;
  }
  .p-sm-8 {
    padding: 8px !important;
  }
  .nm-sm-8 {
    margin: -8px !important;
  }
}
@media (min-width: 600px) {
  .m-sm-12 {
    margin: 12px !important;
  }
  .p-sm-12 {
    padding: 12px !important;
  }
  .nm-sm-12 {
    margin: -12px !important;
  }
}
@media (min-width: 600px) {
  .m-sm-16 {
    margin: 16px !important;
  }
  .p-sm-16 {
    padding: 16px !important;
  }
  .nm-sm-16 {
    margin: -16px !important;
  }
}
@media (min-width: 600px) {
  .m-sm-20 {
    margin: 20px !important;
  }
  .p-sm-20 {
    padding: 20px !important;
  }
  .nm-sm-20 {
    margin: -20px !important;
  }
}
@media (min-width: 600px) {
  .m-sm-24 {
    margin: 24px !important;
  }
  .p-sm-24 {
    padding: 24px !important;
  }
  .nm-sm-24 {
    margin: -24px !important;
  }
}
@media (min-width: 600px) {
  .m-sm-32 {
    margin: 32px !important;
  }
  .p-sm-32 {
    padding: 32px !important;
  }
  .nm-sm-32 {
    margin: -32px !important;
  }
}
@media (min-width: 600px) {
  .m-sm-48 {
    margin: 48px !important;
  }
  .p-sm-48 {
    padding: 48px !important;
  }
  .nm-sm-48 {
    margin: -48px !important;
  }
}
@media (min-width: 600px) {
  .m-sm-64 {
    margin: 64px !important;
  }
  .p-sm-64 {
    padding: 64px !important;
  }
  .nm-sm-64 {
    margin: -64px !important;
  }
}
@media (min-width: 600px) {
  .mt-sm-auto {
    margin-top: auto !important;
  }
}
@media (min-width: 600px) {
  .mt-sm-0 {
    margin-top: 0px !important;
  }
  .pt-sm-0 {
    padding-top: 0px !important;
  }
}
@media (min-width: 600px) {
  .mt-sm-4 {
    margin-top: 4px !important;
  }
  .pt-sm-4 {
    padding-top: 4px !important;
  }
  .nmt-sm-4 {
    margin-top: -4px !important;
  }
}
@media (min-width: 600px) {
  .mt-sm-8 {
    margin-top: 8px !important;
  }
  .pt-sm-8 {
    padding-top: 8px !important;
  }
  .nmt-sm-8 {
    margin-top: -8px !important;
  }
}
@media (min-width: 600px) {
  .mt-sm-12 {
    margin-top: 12px !important;
  }
  .pt-sm-12 {
    padding-top: 12px !important;
  }
  .nmt-sm-12 {
    margin-top: -12px !important;
  }
}
@media (min-width: 600px) {
  .mt-sm-16 {
    margin-top: 16px !important;
  }
  .pt-sm-16 {
    padding-top: 16px !important;
  }
  .nmt-sm-16 {
    margin-top: -16px !important;
  }
}
@media (min-width: 600px) {
  .mt-sm-20 {
    margin-top: 20px !important;
  }
  .pt-sm-20 {
    padding-top: 20px !important;
  }
  .nmt-sm-20 {
    margin-top: -20px !important;
  }
}
@media (min-width: 600px) {
  .mt-sm-24 {
    margin-top: 24px !important;
  }
  .pt-sm-24 {
    padding-top: 24px !important;
  }
  .nmt-sm-24 {
    margin-top: -24px !important;
  }
}
@media (min-width: 600px) {
  .mt-sm-32 {
    margin-top: 32px !important;
  }
  .pt-sm-32 {
    padding-top: 32px !important;
  }
  .nmt-sm-32 {
    margin-top: -32px !important;
  }
}
@media (min-width: 600px) {
  .mt-sm-48 {
    margin-top: 48px !important;
  }
  .pt-sm-48 {
    padding-top: 48px !important;
  }
  .nmt-sm-48 {
    margin-top: -48px !important;
  }
}
@media (min-width: 600px) {
  .mt-sm-64 {
    margin-top: 64px !important;
  }
  .pt-sm-64 {
    padding-top: 64px !important;
  }
  .nmt-sm-64 {
    margin-top: -64px !important;
  }
}
@media (min-width: 600px) {
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
}
@media (min-width: 600px) {
  .mb-sm-0 {
    margin-bottom: 0px !important;
  }
  .pb-sm-0 {
    padding-bottom: 0px !important;
  }
}
@media (min-width: 600px) {
  .mb-sm-4 {
    margin-bottom: 4px !important;
  }
  .pb-sm-4 {
    padding-bottom: 4px !important;
  }
  .nmb-sm-4 {
    margin-bottom: -4px !important;
  }
}
@media (min-width: 600px) {
  .mb-sm-8 {
    margin-bottom: 8px !important;
  }
  .pb-sm-8 {
    padding-bottom: 8px !important;
  }
  .nmb-sm-8 {
    margin-bottom: -8px !important;
  }
}
@media (min-width: 600px) {
  .mb-sm-12 {
    margin-bottom: 12px !important;
  }
  .pb-sm-12 {
    padding-bottom: 12px !important;
  }
  .nmb-sm-12 {
    margin-bottom: -12px !important;
  }
}
@media (min-width: 600px) {
  .mb-sm-16 {
    margin-bottom: 16px !important;
  }
  .pb-sm-16 {
    padding-bottom: 16px !important;
  }
  .nmb-sm-16 {
    margin-bottom: -16px !important;
  }
}
@media (min-width: 600px) {
  .mb-sm-20 {
    margin-bottom: 20px !important;
  }
  .pb-sm-20 {
    padding-bottom: 20px !important;
  }
  .nmb-sm-20 {
    margin-bottom: -20px !important;
  }
}
@media (min-width: 600px) {
  .mb-sm-24 {
    margin-bottom: 24px !important;
  }
  .pb-sm-24 {
    padding-bottom: 24px !important;
  }
  .nmb-sm-24 {
    margin-bottom: -24px !important;
  }
}
@media (min-width: 600px) {
  .mb-sm-32 {
    margin-bottom: 32px !important;
  }
  .pb-sm-32 {
    padding-bottom: 32px !important;
  }
  .nmb-sm-32 {
    margin-bottom: -32px !important;
  }
}
@media (min-width: 600px) {
  .mb-sm-48 {
    margin-bottom: 48px !important;
  }
  .pb-sm-48 {
    padding-bottom: 48px !important;
  }
  .nmb-sm-48 {
    margin-bottom: -48px !important;
  }
}
@media (min-width: 600px) {
  .mb-sm-64 {
    margin-bottom: 64px !important;
  }
  .pb-sm-64 {
    padding-bottom: 64px !important;
  }
  .nmb-sm-64 {
    margin-bottom: -64px !important;
  }
}
@media (min-width: 600px) {
  .ml-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 600px) {
  .ml-sm-0 {
    margin-left: 0px !important;
  }
  .pl-sm-0 {
    padding-left: 0px !important;
  }
}
@media (min-width: 600px) {
  .ml-sm-4 {
    margin-left: 4px !important;
  }
  .pl-sm-4 {
    padding-left: 4px !important;
  }
  .nml-sm-4 {
    margin-left: -4px !important;
  }
}
@media (min-width: 600px) {
  .ml-sm-8 {
    margin-left: 8px !important;
  }
  .pl-sm-8 {
    padding-left: 8px !important;
  }
  .nml-sm-8 {
    margin-left: -8px !important;
  }
}
@media (min-width: 600px) {
  .ml-sm-12 {
    margin-left: 12px !important;
  }
  .pl-sm-12 {
    padding-left: 12px !important;
  }
  .nml-sm-12 {
    margin-left: -12px !important;
  }
}
@media (min-width: 600px) {
  .ml-sm-16 {
    margin-left: 16px !important;
  }
  .pl-sm-16 {
    padding-left: 16px !important;
  }
  .nml-sm-16 {
    margin-left: -16px !important;
  }
}
@media (min-width: 600px) {
  .ml-sm-20 {
    margin-left: 20px !important;
  }
  .pl-sm-20 {
    padding-left: 20px !important;
  }
  .nml-sm-20 {
    margin-left: -20px !important;
  }
}
@media (min-width: 600px) {
  .ml-sm-24 {
    margin-left: 24px !important;
  }
  .pl-sm-24 {
    padding-left: 24px !important;
  }
  .nml-sm-24 {
    margin-left: -24px !important;
  }
}
@media (min-width: 600px) {
  .ml-sm-32 {
    margin-left: 32px !important;
  }
  .pl-sm-32 {
    padding-left: 32px !important;
  }
  .nml-sm-32 {
    margin-left: -32px !important;
  }
}
@media (min-width: 600px) {
  .ml-sm-48 {
    margin-left: 48px !important;
  }
  .pl-sm-48 {
    padding-left: 48px !important;
  }
  .nml-sm-48 {
    margin-left: -48px !important;
  }
}
@media (min-width: 600px) {
  .ml-sm-64 {
    margin-left: 64px !important;
  }
  .pl-sm-64 {
    padding-left: 64px !important;
  }
  .nml-sm-64 {
    margin-left: -64px !important;
  }
}
@media (min-width: 600px) {
  .mr-sm-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 600px) {
  .mr-sm-0 {
    margin-right: 0px !important;
  }
  .pr-sm-0 {
    padding-right: 0px !important;
  }
}
@media (min-width: 600px) {
  .mr-sm-4 {
    margin-right: 4px !important;
  }
  .pr-sm-4 {
    padding-right: 4px !important;
  }
  .nmr-sm-4 {
    margin-right: -4px !important;
  }
}
@media (min-width: 600px) {
  .mr-sm-8 {
    margin-right: 8px !important;
  }
  .pr-sm-8 {
    padding-right: 8px !important;
  }
  .nmr-sm-8 {
    margin-right: -8px !important;
  }
}
@media (min-width: 600px) {
  .mr-sm-12 {
    margin-right: 12px !important;
  }
  .pr-sm-12 {
    padding-right: 12px !important;
  }
  .nmr-sm-12 {
    margin-right: -12px !important;
  }
}
@media (min-width: 600px) {
  .mr-sm-16 {
    margin-right: 16px !important;
  }
  .pr-sm-16 {
    padding-right: 16px !important;
  }
  .nmr-sm-16 {
    margin-right: -16px !important;
  }
}
@media (min-width: 600px) {
  .mr-sm-20 {
    margin-right: 20px !important;
  }
  .pr-sm-20 {
    padding-right: 20px !important;
  }
  .nmr-sm-20 {
    margin-right: -20px !important;
  }
}
@media (min-width: 600px) {
  .mr-sm-24 {
    margin-right: 24px !important;
  }
  .pr-sm-24 {
    padding-right: 24px !important;
  }
  .nmr-sm-24 {
    margin-right: -24px !important;
  }
}
@media (min-width: 600px) {
  .mr-sm-32 {
    margin-right: 32px !important;
  }
  .pr-sm-32 {
    padding-right: 32px !important;
  }
  .nmr-sm-32 {
    margin-right: -32px !important;
  }
}
@media (min-width: 600px) {
  .mr-sm-48 {
    margin-right: 48px !important;
  }
  .pr-sm-48 {
    padding-right: 48px !important;
  }
  .nmr-sm-48 {
    margin-right: -48px !important;
  }
}
@media (min-width: 600px) {
  .mr-sm-64 {
    margin-right: 64px !important;
  }
  .pr-sm-64 {
    padding-right: 64px !important;
  }
  .nmr-sm-64 {
    margin-right: -64px !important;
  }
}
@media (min-width: 600px) {
  .mx-sm-auto {
    margin-inline: auto !important;
  }
}
@media (min-width: 600px) {
  .mx-sm-0 {
    margin-inline: 0px !important;
  }
  .px-sm-0 {
    padding-inline: 0px !important;
  }
}
@media (min-width: 600px) {
  .mx-sm-4 {
    margin-inline: 4px !important;
  }
  .px-sm-4 {
    padding-inline: 4px !important;
  }
  .nmx-sm-4 {
    margin-inline: -4px !important;
  }
}
@media (min-width: 600px) {
  .mx-sm-8 {
    margin-inline: 8px !important;
  }
  .px-sm-8 {
    padding-inline: 8px !important;
  }
  .nmx-sm-8 {
    margin-inline: -8px !important;
  }
}
@media (min-width: 600px) {
  .mx-sm-12 {
    margin-inline: 12px !important;
  }
  .px-sm-12 {
    padding-inline: 12px !important;
  }
  .nmx-sm-12 {
    margin-inline: -12px !important;
  }
}
@media (min-width: 600px) {
  .mx-sm-16 {
    margin-inline: 16px !important;
  }
  .px-sm-16 {
    padding-inline: 16px !important;
  }
  .nmx-sm-16 {
    margin-inline: -16px !important;
  }
}
@media (min-width: 600px) {
  .mx-sm-20 {
    margin-inline: 20px !important;
  }
  .px-sm-20 {
    padding-inline: 20px !important;
  }
  .nmx-sm-20 {
    margin-inline: -20px !important;
  }
}
@media (min-width: 600px) {
  .mx-sm-24 {
    margin-inline: 24px !important;
  }
  .px-sm-24 {
    padding-inline: 24px !important;
  }
  .nmx-sm-24 {
    margin-inline: -24px !important;
  }
}
@media (min-width: 600px) {
  .mx-sm-32 {
    margin-inline: 32px !important;
  }
  .px-sm-32 {
    padding-inline: 32px !important;
  }
  .nmx-sm-32 {
    margin-inline: -32px !important;
  }
}
@media (min-width: 600px) {
  .mx-sm-48 {
    margin-inline: 48px !important;
  }
  .px-sm-48 {
    padding-inline: 48px !important;
  }
  .nmx-sm-48 {
    margin-inline: -48px !important;
  }
}
@media (min-width: 600px) {
  .mx-sm-64 {
    margin-inline: 64px !important;
  }
  .px-sm-64 {
    padding-inline: 64px !important;
  }
  .nmx-sm-64 {
    margin-inline: -64px !important;
  }
}
@media (min-width: 600px) {
  .my-sm-auto {
    margin-block: auto !important;
  }
}
@media (min-width: 600px) {
  .my-sm-0 {
    margin-block: 0px !important;
  }
  .py-sm-0 {
    padding-block: 0px !important;
  }
}
@media (min-width: 600px) {
  .my-sm-4 {
    margin-block: 4px !important;
  }
  .py-sm-4 {
    padding-block: 4px !important;
  }
  .nmy-sm-4 {
    margin-block: -4px !important;
  }
}
@media (min-width: 600px) {
  .my-sm-8 {
    margin-block: 8px !important;
  }
  .py-sm-8 {
    padding-block: 8px !important;
  }
  .nmy-sm-8 {
    margin-block: -8px !important;
  }
}
@media (min-width: 600px) {
  .my-sm-12 {
    margin-block: 12px !important;
  }
  .py-sm-12 {
    padding-block: 12px !important;
  }
  .nmy-sm-12 {
    margin-block: -12px !important;
  }
}
@media (min-width: 600px) {
  .my-sm-16 {
    margin-block: 16px !important;
  }
  .py-sm-16 {
    padding-block: 16px !important;
  }
  .nmy-sm-16 {
    margin-block: -16px !important;
  }
}
@media (min-width: 600px) {
  .my-sm-20 {
    margin-block: 20px !important;
  }
  .py-sm-20 {
    padding-block: 20px !important;
  }
  .nmy-sm-20 {
    margin-block: -20px !important;
  }
}
@media (min-width: 600px) {
  .my-sm-24 {
    margin-block: 24px !important;
  }
  .py-sm-24 {
    padding-block: 24px !important;
  }
  .nmy-sm-24 {
    margin-block: -24px !important;
  }
}
@media (min-width: 600px) {
  .my-sm-32 {
    margin-block: 32px !important;
  }
  .py-sm-32 {
    padding-block: 32px !important;
  }
  .nmy-sm-32 {
    margin-block: -32px !important;
  }
}
@media (min-width: 600px) {
  .my-sm-48 {
    margin-block: 48px !important;
  }
  .py-sm-48 {
    padding-block: 48px !important;
  }
  .nmy-sm-48 {
    margin-block: -48px !important;
  }
}
@media (min-width: 600px) {
  .my-sm-64 {
    margin-block: 64px !important;
  }
  .py-sm-64 {
    padding-block: 64px !important;
  }
  .nmy-sm-64 {
    margin-block: -64px !important;
  }
}
@media (min-width: 960px) {
  .m-md-auto {
    margin: auto !important;
  }
}
@media (min-width: 960px) {
  .m-md-0 {
    margin: 0px !important;
  }
  .p-md-0 {
    padding: 0px !important;
  }
}
@media (min-width: 960px) {
  .m-md-4 {
    margin: 4px !important;
  }
  .p-md-4 {
    padding: 4px !important;
  }
  .nm-md-4 {
    margin: -4px !important;
  }
}
@media (min-width: 960px) {
  .m-md-8 {
    margin: 8px !important;
  }
  .p-md-8 {
    padding: 8px !important;
  }
  .nm-md-8 {
    margin: -8px !important;
  }
}
@media (min-width: 960px) {
  .m-md-12 {
    margin: 12px !important;
  }
  .p-md-12 {
    padding: 12px !important;
  }
  .nm-md-12 {
    margin: -12px !important;
  }
}
@media (min-width: 960px) {
  .m-md-16 {
    margin: 16px !important;
  }
  .p-md-16 {
    padding: 16px !important;
  }
  .nm-md-16 {
    margin: -16px !important;
  }
}
@media (min-width: 960px) {
  .m-md-20 {
    margin: 20px !important;
  }
  .p-md-20 {
    padding: 20px !important;
  }
  .nm-md-20 {
    margin: -20px !important;
  }
}
@media (min-width: 960px) {
  .m-md-24 {
    margin: 24px !important;
  }
  .p-md-24 {
    padding: 24px !important;
  }
  .nm-md-24 {
    margin: -24px !important;
  }
}
@media (min-width: 960px) {
  .m-md-32 {
    margin: 32px !important;
  }
  .p-md-32 {
    padding: 32px !important;
  }
  .nm-md-32 {
    margin: -32px !important;
  }
}
@media (min-width: 960px) {
  .m-md-48 {
    margin: 48px !important;
  }
  .p-md-48 {
    padding: 48px !important;
  }
  .nm-md-48 {
    margin: -48px !important;
  }
}
@media (min-width: 960px) {
  .m-md-64 {
    margin: 64px !important;
  }
  .p-md-64 {
    padding: 64px !important;
  }
  .nm-md-64 {
    margin: -64px !important;
  }
}
@media (min-width: 960px) {
  .mt-md-auto {
    margin-top: auto !important;
  }
}
@media (min-width: 960px) {
  .mt-md-0 {
    margin-top: 0px !important;
  }
  .pt-md-0 {
    padding-top: 0px !important;
  }
}
@media (min-width: 960px) {
  .mt-md-4 {
    margin-top: 4px !important;
  }
  .pt-md-4 {
    padding-top: 4px !important;
  }
  .nmt-md-4 {
    margin-top: -4px !important;
  }
}
@media (min-width: 960px) {
  .mt-md-8 {
    margin-top: 8px !important;
  }
  .pt-md-8 {
    padding-top: 8px !important;
  }
  .nmt-md-8 {
    margin-top: -8px !important;
  }
}
@media (min-width: 960px) {
  .mt-md-12 {
    margin-top: 12px !important;
  }
  .pt-md-12 {
    padding-top: 12px !important;
  }
  .nmt-md-12 {
    margin-top: -12px !important;
  }
}
@media (min-width: 960px) {
  .mt-md-16 {
    margin-top: 16px !important;
  }
  .pt-md-16 {
    padding-top: 16px !important;
  }
  .nmt-md-16 {
    margin-top: -16px !important;
  }
}
@media (min-width: 960px) {
  .mt-md-20 {
    margin-top: 20px !important;
  }
  .pt-md-20 {
    padding-top: 20px !important;
  }
  .nmt-md-20 {
    margin-top: -20px !important;
  }
}
@media (min-width: 960px) {
  .mt-md-24 {
    margin-top: 24px !important;
  }
  .pt-md-24 {
    padding-top: 24px !important;
  }
  .nmt-md-24 {
    margin-top: -24px !important;
  }
}
@media (min-width: 960px) {
  .mt-md-32 {
    margin-top: 32px !important;
  }
  .pt-md-32 {
    padding-top: 32px !important;
  }
  .nmt-md-32 {
    margin-top: -32px !important;
  }
}
@media (min-width: 960px) {
  .mt-md-48 {
    margin-top: 48px !important;
  }
  .pt-md-48 {
    padding-top: 48px !important;
  }
  .nmt-md-48 {
    margin-top: -48px !important;
  }
}
@media (min-width: 960px) {
  .mt-md-64 {
    margin-top: 64px !important;
  }
  .pt-md-64 {
    padding-top: 64px !important;
  }
  .nmt-md-64 {
    margin-top: -64px !important;
  }
}
@media (min-width: 960px) {
  .mb-md-auto {
    margin-bottom: auto !important;
  }
}
@media (min-width: 960px) {
  .mb-md-0 {
    margin-bottom: 0px !important;
  }
  .pb-md-0 {
    padding-bottom: 0px !important;
  }
}
@media (min-width: 960px) {
  .mb-md-4 {
    margin-bottom: 4px !important;
  }
  .pb-md-4 {
    padding-bottom: 4px !important;
  }
  .nmb-md-4 {
    margin-bottom: -4px !important;
  }
}
@media (min-width: 960px) {
  .mb-md-8 {
    margin-bottom: 8px !important;
  }
  .pb-md-8 {
    padding-bottom: 8px !important;
  }
  .nmb-md-8 {
    margin-bottom: -8px !important;
  }
}
@media (min-width: 960px) {
  .mb-md-12 {
    margin-bottom: 12px !important;
  }
  .pb-md-12 {
    padding-bottom: 12px !important;
  }
  .nmb-md-12 {
    margin-bottom: -12px !important;
  }
}
@media (min-width: 960px) {
  .mb-md-16 {
    margin-bottom: 16px !important;
  }
  .pb-md-16 {
    padding-bottom: 16px !important;
  }
  .nmb-md-16 {
    margin-bottom: -16px !important;
  }
}
@media (min-width: 960px) {
  .mb-md-20 {
    margin-bottom: 20px !important;
  }
  .pb-md-20 {
    padding-bottom: 20px !important;
  }
  .nmb-md-20 {
    margin-bottom: -20px !important;
  }
}
@media (min-width: 960px) {
  .mb-md-24 {
    margin-bottom: 24px !important;
  }
  .pb-md-24 {
    padding-bottom: 24px !important;
  }
  .nmb-md-24 {
    margin-bottom: -24px !important;
  }
}
@media (min-width: 960px) {
  .mb-md-32 {
    margin-bottom: 32px !important;
  }
  .pb-md-32 {
    padding-bottom: 32px !important;
  }
  .nmb-md-32 {
    margin-bottom: -32px !important;
  }
}
@media (min-width: 960px) {
  .mb-md-48 {
    margin-bottom: 48px !important;
  }
  .pb-md-48 {
    padding-bottom: 48px !important;
  }
  .nmb-md-48 {
    margin-bottom: -48px !important;
  }
}
@media (min-width: 960px) {
  .mb-md-64 {
    margin-bottom: 64px !important;
  }
  .pb-md-64 {
    padding-bottom: 64px !important;
  }
  .nmb-md-64 {
    margin-bottom: -64px !important;
  }
}
@media (min-width: 960px) {
  .ml-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 960px) {
  .ml-md-0 {
    margin-left: 0px !important;
  }
  .pl-md-0 {
    padding-left: 0px !important;
  }
}
@media (min-width: 960px) {
  .ml-md-4 {
    margin-left: 4px !important;
  }
  .pl-md-4 {
    padding-left: 4px !important;
  }
  .nml-md-4 {
    margin-left: -4px !important;
  }
}
@media (min-width: 960px) {
  .ml-md-8 {
    margin-left: 8px !important;
  }
  .pl-md-8 {
    padding-left: 8px !important;
  }
  .nml-md-8 {
    margin-left: -8px !important;
  }
}
@media (min-width: 960px) {
  .ml-md-12 {
    margin-left: 12px !important;
  }
  .pl-md-12 {
    padding-left: 12px !important;
  }
  .nml-md-12 {
    margin-left: -12px !important;
  }
}
@media (min-width: 960px) {
  .ml-md-16 {
    margin-left: 16px !important;
  }
  .pl-md-16 {
    padding-left: 16px !important;
  }
  .nml-md-16 {
    margin-left: -16px !important;
  }
}
@media (min-width: 960px) {
  .ml-md-20 {
    margin-left: 20px !important;
  }
  .pl-md-20 {
    padding-left: 20px !important;
  }
  .nml-md-20 {
    margin-left: -20px !important;
  }
}
@media (min-width: 960px) {
  .ml-md-24 {
    margin-left: 24px !important;
  }
  .pl-md-24 {
    padding-left: 24px !important;
  }
  .nml-md-24 {
    margin-left: -24px !important;
  }
}
@media (min-width: 960px) {
  .ml-md-32 {
    margin-left: 32px !important;
  }
  .pl-md-32 {
    padding-left: 32px !important;
  }
  .nml-md-32 {
    margin-left: -32px !important;
  }
}
@media (min-width: 960px) {
  .ml-md-48 {
    margin-left: 48px !important;
  }
  .pl-md-48 {
    padding-left: 48px !important;
  }
  .nml-md-48 {
    margin-left: -48px !important;
  }
}
@media (min-width: 960px) {
  .ml-md-64 {
    margin-left: 64px !important;
  }
  .pl-md-64 {
    padding-left: 64px !important;
  }
  .nml-md-64 {
    margin-left: -64px !important;
  }
}
@media (min-width: 960px) {
  .mr-md-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 960px) {
  .mr-md-0 {
    margin-right: 0px !important;
  }
  .pr-md-0 {
    padding-right: 0px !important;
  }
}
@media (min-width: 960px) {
  .mr-md-4 {
    margin-right: 4px !important;
  }
  .pr-md-4 {
    padding-right: 4px !important;
  }
  .nmr-md-4 {
    margin-right: -4px !important;
  }
}
@media (min-width: 960px) {
  .mr-md-8 {
    margin-right: 8px !important;
  }
  .pr-md-8 {
    padding-right: 8px !important;
  }
  .nmr-md-8 {
    margin-right: -8px !important;
  }
}
@media (min-width: 960px) {
  .mr-md-12 {
    margin-right: 12px !important;
  }
  .pr-md-12 {
    padding-right: 12px !important;
  }
  .nmr-md-12 {
    margin-right: -12px !important;
  }
}
@media (min-width: 960px) {
  .mr-md-16 {
    margin-right: 16px !important;
  }
  .pr-md-16 {
    padding-right: 16px !important;
  }
  .nmr-md-16 {
    margin-right: -16px !important;
  }
}
@media (min-width: 960px) {
  .mr-md-20 {
    margin-right: 20px !important;
  }
  .pr-md-20 {
    padding-right: 20px !important;
  }
  .nmr-md-20 {
    margin-right: -20px !important;
  }
}
@media (min-width: 960px) {
  .mr-md-24 {
    margin-right: 24px !important;
  }
  .pr-md-24 {
    padding-right: 24px !important;
  }
  .nmr-md-24 {
    margin-right: -24px !important;
  }
}
@media (min-width: 960px) {
  .mr-md-32 {
    margin-right: 32px !important;
  }
  .pr-md-32 {
    padding-right: 32px !important;
  }
  .nmr-md-32 {
    margin-right: -32px !important;
  }
}
@media (min-width: 960px) {
  .mr-md-48 {
    margin-right: 48px !important;
  }
  .pr-md-48 {
    padding-right: 48px !important;
  }
  .nmr-md-48 {
    margin-right: -48px !important;
  }
}
@media (min-width: 960px) {
  .mr-md-64 {
    margin-right: 64px !important;
  }
  .pr-md-64 {
    padding-right: 64px !important;
  }
  .nmr-md-64 {
    margin-right: -64px !important;
  }
}
@media (min-width: 960px) {
  .mx-md-auto {
    margin-inline: auto !important;
  }
}
@media (min-width: 960px) {
  .mx-md-0 {
    margin-inline: 0px !important;
  }
  .px-md-0 {
    padding-inline: 0px !important;
  }
}
@media (min-width: 960px) {
  .mx-md-4 {
    margin-inline: 4px !important;
  }
  .px-md-4 {
    padding-inline: 4px !important;
  }
  .nmx-md-4 {
    margin-inline: -4px !important;
  }
}
@media (min-width: 960px) {
  .mx-md-8 {
    margin-inline: 8px !important;
  }
  .px-md-8 {
    padding-inline: 8px !important;
  }
  .nmx-md-8 {
    margin-inline: -8px !important;
  }
}
@media (min-width: 960px) {
  .mx-md-12 {
    margin-inline: 12px !important;
  }
  .px-md-12 {
    padding-inline: 12px !important;
  }
  .nmx-md-12 {
    margin-inline: -12px !important;
  }
}
@media (min-width: 960px) {
  .mx-md-16 {
    margin-inline: 16px !important;
  }
  .px-md-16 {
    padding-inline: 16px !important;
  }
  .nmx-md-16 {
    margin-inline: -16px !important;
  }
}
@media (min-width: 960px) {
  .mx-md-20 {
    margin-inline: 20px !important;
  }
  .px-md-20 {
    padding-inline: 20px !important;
  }
  .nmx-md-20 {
    margin-inline: -20px !important;
  }
}
@media (min-width: 960px) {
  .mx-md-24 {
    margin-inline: 24px !important;
  }
  .px-md-24 {
    padding-inline: 24px !important;
  }
  .nmx-md-24 {
    margin-inline: -24px !important;
  }
}
@media (min-width: 960px) {
  .mx-md-32 {
    margin-inline: 32px !important;
  }
  .px-md-32 {
    padding-inline: 32px !important;
  }
  .nmx-md-32 {
    margin-inline: -32px !important;
  }
}
@media (min-width: 960px) {
  .mx-md-48 {
    margin-inline: 48px !important;
  }
  .px-md-48 {
    padding-inline: 48px !important;
  }
  .nmx-md-48 {
    margin-inline: -48px !important;
  }
}
@media (min-width: 960px) {
  .mx-md-64 {
    margin-inline: 64px !important;
  }
  .px-md-64 {
    padding-inline: 64px !important;
  }
  .nmx-md-64 {
    margin-inline: -64px !important;
  }
}
@media (min-width: 960px) {
  .my-md-auto {
    margin-block: auto !important;
  }
}
@media (min-width: 960px) {
  .my-md-0 {
    margin-block: 0px !important;
  }
  .py-md-0 {
    padding-block: 0px !important;
  }
}
@media (min-width: 960px) {
  .my-md-4 {
    margin-block: 4px !important;
  }
  .py-md-4 {
    padding-block: 4px !important;
  }
  .nmy-md-4 {
    margin-block: -4px !important;
  }
}
@media (min-width: 960px) {
  .my-md-8 {
    margin-block: 8px !important;
  }
  .py-md-8 {
    padding-block: 8px !important;
  }
  .nmy-md-8 {
    margin-block: -8px !important;
  }
}
@media (min-width: 960px) {
  .my-md-12 {
    margin-block: 12px !important;
  }
  .py-md-12 {
    padding-block: 12px !important;
  }
  .nmy-md-12 {
    margin-block: -12px !important;
  }
}
@media (min-width: 960px) {
  .my-md-16 {
    margin-block: 16px !important;
  }
  .py-md-16 {
    padding-block: 16px !important;
  }
  .nmy-md-16 {
    margin-block: -16px !important;
  }
}
@media (min-width: 960px) {
  .my-md-20 {
    margin-block: 20px !important;
  }
  .py-md-20 {
    padding-block: 20px !important;
  }
  .nmy-md-20 {
    margin-block: -20px !important;
  }
}
@media (min-width: 960px) {
  .my-md-24 {
    margin-block: 24px !important;
  }
  .py-md-24 {
    padding-block: 24px !important;
  }
  .nmy-md-24 {
    margin-block: -24px !important;
  }
}
@media (min-width: 960px) {
  .my-md-32 {
    margin-block: 32px !important;
  }
  .py-md-32 {
    padding-block: 32px !important;
  }
  .nmy-md-32 {
    margin-block: -32px !important;
  }
}
@media (min-width: 960px) {
  .my-md-48 {
    margin-block: 48px !important;
  }
  .py-md-48 {
    padding-block: 48px !important;
  }
  .nmy-md-48 {
    margin-block: -48px !important;
  }
}
@media (min-width: 960px) {
  .my-md-64 {
    margin-block: 64px !important;
  }
  .py-md-64 {
    padding-block: 64px !important;
  }
  .nmy-md-64 {
    margin-block: -64px !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-auto {
    margin: auto !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-0 {
    margin: 0px !important;
  }
  .p-lg-0 {
    padding: 0px !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-4 {
    margin: 4px !important;
  }
  .p-lg-4 {
    padding: 4px !important;
  }
  .nm-lg-4 {
    margin: -4px !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-8 {
    margin: 8px !important;
  }
  .p-lg-8 {
    padding: 8px !important;
  }
  .nm-lg-8 {
    margin: -8px !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-12 {
    margin: 12px !important;
  }
  .p-lg-12 {
    padding: 12px !important;
  }
  .nm-lg-12 {
    margin: -12px !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-16 {
    margin: 16px !important;
  }
  .p-lg-16 {
    padding: 16px !important;
  }
  .nm-lg-16 {
    margin: -16px !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-20 {
    margin: 20px !important;
  }
  .p-lg-20 {
    padding: 20px !important;
  }
  .nm-lg-20 {
    margin: -20px !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-24 {
    margin: 24px !important;
  }
  .p-lg-24 {
    padding: 24px !important;
  }
  .nm-lg-24 {
    margin: -24px !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-32 {
    margin: 32px !important;
  }
  .p-lg-32 {
    padding: 32px !important;
  }
  .nm-lg-32 {
    margin: -32px !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-48 {
    margin: 48px !important;
  }
  .p-lg-48 {
    padding: 48px !important;
  }
  .nm-lg-48 {
    margin: -48px !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-64 {
    margin: 64px !important;
  }
  .p-lg-64 {
    padding: 64px !important;
  }
  .nm-lg-64 {
    margin: -64px !important;
  }
}
@media (min-width: 1280px) {
  .mt-lg-auto {
    margin-top: auto !important;
  }
}
@media (min-width: 1280px) {
  .mt-lg-0 {
    margin-top: 0px !important;
  }
  .pt-lg-0 {
    padding-top: 0px !important;
  }
}
@media (min-width: 1280px) {
  .mt-lg-4 {
    margin-top: 4px !important;
  }
  .pt-lg-4 {
    padding-top: 4px !important;
  }
  .nmt-lg-4 {
    margin-top: -4px !important;
  }
}
@media (min-width: 1280px) {
  .mt-lg-8 {
    margin-top: 8px !important;
  }
  .pt-lg-8 {
    padding-top: 8px !important;
  }
  .nmt-lg-8 {
    margin-top: -8px !important;
  }
}
@media (min-width: 1280px) {
  .mt-lg-12 {
    margin-top: 12px !important;
  }
  .pt-lg-12 {
    padding-top: 12px !important;
  }
  .nmt-lg-12 {
    margin-top: -12px !important;
  }
}
@media (min-width: 1280px) {
  .mt-lg-16 {
    margin-top: 16px !important;
  }
  .pt-lg-16 {
    padding-top: 16px !important;
  }
  .nmt-lg-16 {
    margin-top: -16px !important;
  }
}
@media (min-width: 1280px) {
  .mt-lg-20 {
    margin-top: 20px !important;
  }
  .pt-lg-20 {
    padding-top: 20px !important;
  }
  .nmt-lg-20 {
    margin-top: -20px !important;
  }
}
@media (min-width: 1280px) {
  .mt-lg-24 {
    margin-top: 24px !important;
  }
  .pt-lg-24 {
    padding-top: 24px !important;
  }
  .nmt-lg-24 {
    margin-top: -24px !important;
  }
}
@media (min-width: 1280px) {
  .mt-lg-32 {
    margin-top: 32px !important;
  }
  .pt-lg-32 {
    padding-top: 32px !important;
  }
  .nmt-lg-32 {
    margin-top: -32px !important;
  }
}
@media (min-width: 1280px) {
  .mt-lg-48 {
    margin-top: 48px !important;
  }
  .pt-lg-48 {
    padding-top: 48px !important;
  }
  .nmt-lg-48 {
    margin-top: -48px !important;
  }
}
@media (min-width: 1280px) {
  .mt-lg-64 {
    margin-top: 64px !important;
  }
  .pt-lg-64 {
    padding-top: 64px !important;
  }
  .nmt-lg-64 {
    margin-top: -64px !important;
  }
}
@media (min-width: 1280px) {
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
}
@media (min-width: 1280px) {
  .mb-lg-0 {
    margin-bottom: 0px !important;
  }
  .pb-lg-0 {
    padding-bottom: 0px !important;
  }
}
@media (min-width: 1280px) {
  .mb-lg-4 {
    margin-bottom: 4px !important;
  }
  .pb-lg-4 {
    padding-bottom: 4px !important;
  }
  .nmb-lg-4 {
    margin-bottom: -4px !important;
  }
}
@media (min-width: 1280px) {
  .mb-lg-8 {
    margin-bottom: 8px !important;
  }
  .pb-lg-8 {
    padding-bottom: 8px !important;
  }
  .nmb-lg-8 {
    margin-bottom: -8px !important;
  }
}
@media (min-width: 1280px) {
  .mb-lg-12 {
    margin-bottom: 12px !important;
  }
  .pb-lg-12 {
    padding-bottom: 12px !important;
  }
  .nmb-lg-12 {
    margin-bottom: -12px !important;
  }
}
@media (min-width: 1280px) {
  .mb-lg-16 {
    margin-bottom: 16px !important;
  }
  .pb-lg-16 {
    padding-bottom: 16px !important;
  }
  .nmb-lg-16 {
    margin-bottom: -16px !important;
  }
}
@media (min-width: 1280px) {
  .mb-lg-20 {
    margin-bottom: 20px !important;
  }
  .pb-lg-20 {
    padding-bottom: 20px !important;
  }
  .nmb-lg-20 {
    margin-bottom: -20px !important;
  }
}
@media (min-width: 1280px) {
  .mb-lg-24 {
    margin-bottom: 24px !important;
  }
  .pb-lg-24 {
    padding-bottom: 24px !important;
  }
  .nmb-lg-24 {
    margin-bottom: -24px !important;
  }
}
@media (min-width: 1280px) {
  .mb-lg-32 {
    margin-bottom: 32px !important;
  }
  .pb-lg-32 {
    padding-bottom: 32px !important;
  }
  .nmb-lg-32 {
    margin-bottom: -32px !important;
  }
}
@media (min-width: 1280px) {
  .mb-lg-48 {
    margin-bottom: 48px !important;
  }
  .pb-lg-48 {
    padding-bottom: 48px !important;
  }
  .nmb-lg-48 {
    margin-bottom: -48px !important;
  }
}
@media (min-width: 1280px) {
  .mb-lg-64 {
    margin-bottom: 64px !important;
  }
  .pb-lg-64 {
    padding-bottom: 64px !important;
  }
  .nmb-lg-64 {
    margin-bottom: -64px !important;
  }
}
@media (min-width: 1280px) {
  .ml-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1280px) {
  .ml-lg-0 {
    margin-left: 0px !important;
  }
  .pl-lg-0 {
    padding-left: 0px !important;
  }
}
@media (min-width: 1280px) {
  .ml-lg-4 {
    margin-left: 4px !important;
  }
  .pl-lg-4 {
    padding-left: 4px !important;
  }
  .nml-lg-4 {
    margin-left: -4px !important;
  }
}
@media (min-width: 1280px) {
  .ml-lg-8 {
    margin-left: 8px !important;
  }
  .pl-lg-8 {
    padding-left: 8px !important;
  }
  .nml-lg-8 {
    margin-left: -8px !important;
  }
}
@media (min-width: 1280px) {
  .ml-lg-12 {
    margin-left: 12px !important;
  }
  .pl-lg-12 {
    padding-left: 12px !important;
  }
  .nml-lg-12 {
    margin-left: -12px !important;
  }
}
@media (min-width: 1280px) {
  .ml-lg-16 {
    margin-left: 16px !important;
  }
  .pl-lg-16 {
    padding-left: 16px !important;
  }
  .nml-lg-16 {
    margin-left: -16px !important;
  }
}
@media (min-width: 1280px) {
  .ml-lg-20 {
    margin-left: 20px !important;
  }
  .pl-lg-20 {
    padding-left: 20px !important;
  }
  .nml-lg-20 {
    margin-left: -20px !important;
  }
}
@media (min-width: 1280px) {
  .ml-lg-24 {
    margin-left: 24px !important;
  }
  .pl-lg-24 {
    padding-left: 24px !important;
  }
  .nml-lg-24 {
    margin-left: -24px !important;
  }
}
@media (min-width: 1280px) {
  .ml-lg-32 {
    margin-left: 32px !important;
  }
  .pl-lg-32 {
    padding-left: 32px !important;
  }
  .nml-lg-32 {
    margin-left: -32px !important;
  }
}
@media (min-width: 1280px) {
  .ml-lg-48 {
    margin-left: 48px !important;
  }
  .pl-lg-48 {
    padding-left: 48px !important;
  }
  .nml-lg-48 {
    margin-left: -48px !important;
  }
}
@media (min-width: 1280px) {
  .ml-lg-64 {
    margin-left: 64px !important;
  }
  .pl-lg-64 {
    padding-left: 64px !important;
  }
  .nml-lg-64 {
    margin-left: -64px !important;
  }
}
@media (min-width: 1280px) {
  .mr-lg-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 1280px) {
  .mr-lg-0 {
    margin-right: 0px !important;
  }
  .pr-lg-0 {
    padding-right: 0px !important;
  }
}
@media (min-width: 1280px) {
  .mr-lg-4 {
    margin-right: 4px !important;
  }
  .pr-lg-4 {
    padding-right: 4px !important;
  }
  .nmr-lg-4 {
    margin-right: -4px !important;
  }
}
@media (min-width: 1280px) {
  .mr-lg-8 {
    margin-right: 8px !important;
  }
  .pr-lg-8 {
    padding-right: 8px !important;
  }
  .nmr-lg-8 {
    margin-right: -8px !important;
  }
}
@media (min-width: 1280px) {
  .mr-lg-12 {
    margin-right: 12px !important;
  }
  .pr-lg-12 {
    padding-right: 12px !important;
  }
  .nmr-lg-12 {
    margin-right: -12px !important;
  }
}
@media (min-width: 1280px) {
  .mr-lg-16 {
    margin-right: 16px !important;
  }
  .pr-lg-16 {
    padding-right: 16px !important;
  }
  .nmr-lg-16 {
    margin-right: -16px !important;
  }
}
@media (min-width: 1280px) {
  .mr-lg-20 {
    margin-right: 20px !important;
  }
  .pr-lg-20 {
    padding-right: 20px !important;
  }
  .nmr-lg-20 {
    margin-right: -20px !important;
  }
}
@media (min-width: 1280px) {
  .mr-lg-24 {
    margin-right: 24px !important;
  }
  .pr-lg-24 {
    padding-right: 24px !important;
  }
  .nmr-lg-24 {
    margin-right: -24px !important;
  }
}
@media (min-width: 1280px) {
  .mr-lg-32 {
    margin-right: 32px !important;
  }
  .pr-lg-32 {
    padding-right: 32px !important;
  }
  .nmr-lg-32 {
    margin-right: -32px !important;
  }
}
@media (min-width: 1280px) {
  .mr-lg-48 {
    margin-right: 48px !important;
  }
  .pr-lg-48 {
    padding-right: 48px !important;
  }
  .nmr-lg-48 {
    margin-right: -48px !important;
  }
}
@media (min-width: 1280px) {
  .mr-lg-64 {
    margin-right: 64px !important;
  }
  .pr-lg-64 {
    padding-right: 64px !important;
  }
  .nmr-lg-64 {
    margin-right: -64px !important;
  }
}
@media (min-width: 1280px) {
  .mx-lg-auto {
    margin-inline: auto !important;
  }
}
@media (min-width: 1280px) {
  .mx-lg-0 {
    margin-inline: 0px !important;
  }
  .px-lg-0 {
    padding-inline: 0px !important;
  }
}
@media (min-width: 1280px) {
  .mx-lg-4 {
    margin-inline: 4px !important;
  }
  .px-lg-4 {
    padding-inline: 4px !important;
  }
  .nmx-lg-4 {
    margin-inline: -4px !important;
  }
}
@media (min-width: 1280px) {
  .mx-lg-8 {
    margin-inline: 8px !important;
  }
  .px-lg-8 {
    padding-inline: 8px !important;
  }
  .nmx-lg-8 {
    margin-inline: -8px !important;
  }
}
@media (min-width: 1280px) {
  .mx-lg-12 {
    margin-inline: 12px !important;
  }
  .px-lg-12 {
    padding-inline: 12px !important;
  }
  .nmx-lg-12 {
    margin-inline: -12px !important;
  }
}
@media (min-width: 1280px) {
  .mx-lg-16 {
    margin-inline: 16px !important;
  }
  .px-lg-16 {
    padding-inline: 16px !important;
  }
  .nmx-lg-16 {
    margin-inline: -16px !important;
  }
}
@media (min-width: 1280px) {
  .mx-lg-20 {
    margin-inline: 20px !important;
  }
  .px-lg-20 {
    padding-inline: 20px !important;
  }
  .nmx-lg-20 {
    margin-inline: -20px !important;
  }
}
@media (min-width: 1280px) {
  .mx-lg-24 {
    margin-inline: 24px !important;
  }
  .px-lg-24 {
    padding-inline: 24px !important;
  }
  .nmx-lg-24 {
    margin-inline: -24px !important;
  }
}
@media (min-width: 1280px) {
  .mx-lg-32 {
    margin-inline: 32px !important;
  }
  .px-lg-32 {
    padding-inline: 32px !important;
  }
  .nmx-lg-32 {
    margin-inline: -32px !important;
  }
}
@media (min-width: 1280px) {
  .mx-lg-48 {
    margin-inline: 48px !important;
  }
  .px-lg-48 {
    padding-inline: 48px !important;
  }
  .nmx-lg-48 {
    margin-inline: -48px !important;
  }
}
@media (min-width: 1280px) {
  .mx-lg-64 {
    margin-inline: 64px !important;
  }
  .px-lg-64 {
    padding-inline: 64px !important;
  }
  .nmx-lg-64 {
    margin-inline: -64px !important;
  }
}
@media (min-width: 1280px) {
  .my-lg-auto {
    margin-block: auto !important;
  }
}
@media (min-width: 1280px) {
  .my-lg-0 {
    margin-block: 0px !important;
  }
  .py-lg-0 {
    padding-block: 0px !important;
  }
}
@media (min-width: 1280px) {
  .my-lg-4 {
    margin-block: 4px !important;
  }
  .py-lg-4 {
    padding-block: 4px !important;
  }
  .nmy-lg-4 {
    margin-block: -4px !important;
  }
}
@media (min-width: 1280px) {
  .my-lg-8 {
    margin-block: 8px !important;
  }
  .py-lg-8 {
    padding-block: 8px !important;
  }
  .nmy-lg-8 {
    margin-block: -8px !important;
  }
}
@media (min-width: 1280px) {
  .my-lg-12 {
    margin-block: 12px !important;
  }
  .py-lg-12 {
    padding-block: 12px !important;
  }
  .nmy-lg-12 {
    margin-block: -12px !important;
  }
}
@media (min-width: 1280px) {
  .my-lg-16 {
    margin-block: 16px !important;
  }
  .py-lg-16 {
    padding-block: 16px !important;
  }
  .nmy-lg-16 {
    margin-block: -16px !important;
  }
}
@media (min-width: 1280px) {
  .my-lg-20 {
    margin-block: 20px !important;
  }
  .py-lg-20 {
    padding-block: 20px !important;
  }
  .nmy-lg-20 {
    margin-block: -20px !important;
  }
}
@media (min-width: 1280px) {
  .my-lg-24 {
    margin-block: 24px !important;
  }
  .py-lg-24 {
    padding-block: 24px !important;
  }
  .nmy-lg-24 {
    margin-block: -24px !important;
  }
}
@media (min-width: 1280px) {
  .my-lg-32 {
    margin-block: 32px !important;
  }
  .py-lg-32 {
    padding-block: 32px !important;
  }
  .nmy-lg-32 {
    margin-block: -32px !important;
  }
}
@media (min-width: 1280px) {
  .my-lg-48 {
    margin-block: 48px !important;
  }
  .py-lg-48 {
    padding-block: 48px !important;
  }
  .nmy-lg-48 {
    margin-block: -48px !important;
  }
}
@media (min-width: 1280px) {
  .my-lg-64 {
    margin-block: 64px !important;
  }
  .py-lg-64 {
    padding-block: 64px !important;
  }
  .nmy-lg-64 {
    margin-block: -64px !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-auto {
    margin: auto !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-0 {
    margin: 0px !important;
  }
  .p-xl-0 {
    padding: 0px !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-4 {
    margin: 4px !important;
  }
  .p-xl-4 {
    padding: 4px !important;
  }
  .nm-xl-4 {
    margin: -4px !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-8 {
    margin: 8px !important;
  }
  .p-xl-8 {
    padding: 8px !important;
  }
  .nm-xl-8 {
    margin: -8px !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-12 {
    margin: 12px !important;
  }
  .p-xl-12 {
    padding: 12px !important;
  }
  .nm-xl-12 {
    margin: -12px !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-16 {
    margin: 16px !important;
  }
  .p-xl-16 {
    padding: 16px !important;
  }
  .nm-xl-16 {
    margin: -16px !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-20 {
    margin: 20px !important;
  }
  .p-xl-20 {
    padding: 20px !important;
  }
  .nm-xl-20 {
    margin: -20px !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-24 {
    margin: 24px !important;
  }
  .p-xl-24 {
    padding: 24px !important;
  }
  .nm-xl-24 {
    margin: -24px !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-32 {
    margin: 32px !important;
  }
  .p-xl-32 {
    padding: 32px !important;
  }
  .nm-xl-32 {
    margin: -32px !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-48 {
    margin: 48px !important;
  }
  .p-xl-48 {
    padding: 48px !important;
  }
  .nm-xl-48 {
    margin: -48px !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-64 {
    margin: 64px !important;
  }
  .p-xl-64 {
    padding: 64px !important;
  }
  .nm-xl-64 {
    margin: -64px !important;
  }
}
@media (min-width: 1920px) {
  .mt-xl-auto {
    margin-top: auto !important;
  }
}
@media (min-width: 1920px) {
  .mt-xl-0 {
    margin-top: 0px !important;
  }
  .pt-xl-0 {
    padding-top: 0px !important;
  }
}
@media (min-width: 1920px) {
  .mt-xl-4 {
    margin-top: 4px !important;
  }
  .pt-xl-4 {
    padding-top: 4px !important;
  }
  .nmt-xl-4 {
    margin-top: -4px !important;
  }
}
@media (min-width: 1920px) {
  .mt-xl-8 {
    margin-top: 8px !important;
  }
  .pt-xl-8 {
    padding-top: 8px !important;
  }
  .nmt-xl-8 {
    margin-top: -8px !important;
  }
}
@media (min-width: 1920px) {
  .mt-xl-12 {
    margin-top: 12px !important;
  }
  .pt-xl-12 {
    padding-top: 12px !important;
  }
  .nmt-xl-12 {
    margin-top: -12px !important;
  }
}
@media (min-width: 1920px) {
  .mt-xl-16 {
    margin-top: 16px !important;
  }
  .pt-xl-16 {
    padding-top: 16px !important;
  }
  .nmt-xl-16 {
    margin-top: -16px !important;
  }
}
@media (min-width: 1920px) {
  .mt-xl-20 {
    margin-top: 20px !important;
  }
  .pt-xl-20 {
    padding-top: 20px !important;
  }
  .nmt-xl-20 {
    margin-top: -20px !important;
  }
}
@media (min-width: 1920px) {
  .mt-xl-24 {
    margin-top: 24px !important;
  }
  .pt-xl-24 {
    padding-top: 24px !important;
  }
  .nmt-xl-24 {
    margin-top: -24px !important;
  }
}
@media (min-width: 1920px) {
  .mt-xl-32 {
    margin-top: 32px !important;
  }
  .pt-xl-32 {
    padding-top: 32px !important;
  }
  .nmt-xl-32 {
    margin-top: -32px !important;
  }
}
@media (min-width: 1920px) {
  .mt-xl-48 {
    margin-top: 48px !important;
  }
  .pt-xl-48 {
    padding-top: 48px !important;
  }
  .nmt-xl-48 {
    margin-top: -48px !important;
  }
}
@media (min-width: 1920px) {
  .mt-xl-64 {
    margin-top: 64px !important;
  }
  .pt-xl-64 {
    padding-top: 64px !important;
  }
  .nmt-xl-64 {
    margin-top: -64px !important;
  }
}
@media (min-width: 1920px) {
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
}
@media (min-width: 1920px) {
  .mb-xl-0 {
    margin-bottom: 0px !important;
  }
  .pb-xl-0 {
    padding-bottom: 0px !important;
  }
}
@media (min-width: 1920px) {
  .mb-xl-4 {
    margin-bottom: 4px !important;
  }
  .pb-xl-4 {
    padding-bottom: 4px !important;
  }
  .nmb-xl-4 {
    margin-bottom: -4px !important;
  }
}
@media (min-width: 1920px) {
  .mb-xl-8 {
    margin-bottom: 8px !important;
  }
  .pb-xl-8 {
    padding-bottom: 8px !important;
  }
  .nmb-xl-8 {
    margin-bottom: -8px !important;
  }
}
@media (min-width: 1920px) {
  .mb-xl-12 {
    margin-bottom: 12px !important;
  }
  .pb-xl-12 {
    padding-bottom: 12px !important;
  }
  .nmb-xl-12 {
    margin-bottom: -12px !important;
  }
}
@media (min-width: 1920px) {
  .mb-xl-16 {
    margin-bottom: 16px !important;
  }
  .pb-xl-16 {
    padding-bottom: 16px !important;
  }
  .nmb-xl-16 {
    margin-bottom: -16px !important;
  }
}
@media (min-width: 1920px) {
  .mb-xl-20 {
    margin-bottom: 20px !important;
  }
  .pb-xl-20 {
    padding-bottom: 20px !important;
  }
  .nmb-xl-20 {
    margin-bottom: -20px !important;
  }
}
@media (min-width: 1920px) {
  .mb-xl-24 {
    margin-bottom: 24px !important;
  }
  .pb-xl-24 {
    padding-bottom: 24px !important;
  }
  .nmb-xl-24 {
    margin-bottom: -24px !important;
  }
}
@media (min-width: 1920px) {
  .mb-xl-32 {
    margin-bottom: 32px !important;
  }
  .pb-xl-32 {
    padding-bottom: 32px !important;
  }
  .nmb-xl-32 {
    margin-bottom: -32px !important;
  }
}
@media (min-width: 1920px) {
  .mb-xl-48 {
    margin-bottom: 48px !important;
  }
  .pb-xl-48 {
    padding-bottom: 48px !important;
  }
  .nmb-xl-48 {
    margin-bottom: -48px !important;
  }
}
@media (min-width: 1920px) {
  .mb-xl-64 {
    margin-bottom: 64px !important;
  }
  .pb-xl-64 {
    padding-bottom: 64px !important;
  }
  .nmb-xl-64 {
    margin-bottom: -64px !important;
  }
}
@media (min-width: 1920px) {
  .ml-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1920px) {
  .ml-xl-0 {
    margin-left: 0px !important;
  }
  .pl-xl-0 {
    padding-left: 0px !important;
  }
}
@media (min-width: 1920px) {
  .ml-xl-4 {
    margin-left: 4px !important;
  }
  .pl-xl-4 {
    padding-left: 4px !important;
  }
  .nml-xl-4 {
    margin-left: -4px !important;
  }
}
@media (min-width: 1920px) {
  .ml-xl-8 {
    margin-left: 8px !important;
  }
  .pl-xl-8 {
    padding-left: 8px !important;
  }
  .nml-xl-8 {
    margin-left: -8px !important;
  }
}
@media (min-width: 1920px) {
  .ml-xl-12 {
    margin-left: 12px !important;
  }
  .pl-xl-12 {
    padding-left: 12px !important;
  }
  .nml-xl-12 {
    margin-left: -12px !important;
  }
}
@media (min-width: 1920px) {
  .ml-xl-16 {
    margin-left: 16px !important;
  }
  .pl-xl-16 {
    padding-left: 16px !important;
  }
  .nml-xl-16 {
    margin-left: -16px !important;
  }
}
@media (min-width: 1920px) {
  .ml-xl-20 {
    margin-left: 20px !important;
  }
  .pl-xl-20 {
    padding-left: 20px !important;
  }
  .nml-xl-20 {
    margin-left: -20px !important;
  }
}
@media (min-width: 1920px) {
  .ml-xl-24 {
    margin-left: 24px !important;
  }
  .pl-xl-24 {
    padding-left: 24px !important;
  }
  .nml-xl-24 {
    margin-left: -24px !important;
  }
}
@media (min-width: 1920px) {
  .ml-xl-32 {
    margin-left: 32px !important;
  }
  .pl-xl-32 {
    padding-left: 32px !important;
  }
  .nml-xl-32 {
    margin-left: -32px !important;
  }
}
@media (min-width: 1920px) {
  .ml-xl-48 {
    margin-left: 48px !important;
  }
  .pl-xl-48 {
    padding-left: 48px !important;
  }
  .nml-xl-48 {
    margin-left: -48px !important;
  }
}
@media (min-width: 1920px) {
  .ml-xl-64 {
    margin-left: 64px !important;
  }
  .pl-xl-64 {
    padding-left: 64px !important;
  }
  .nml-xl-64 {
    margin-left: -64px !important;
  }
}
@media (min-width: 1920px) {
  .mr-xl-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 1920px) {
  .mr-xl-0 {
    margin-right: 0px !important;
  }
  .pr-xl-0 {
    padding-right: 0px !important;
  }
}
@media (min-width: 1920px) {
  .mr-xl-4 {
    margin-right: 4px !important;
  }
  .pr-xl-4 {
    padding-right: 4px !important;
  }
  .nmr-xl-4 {
    margin-right: -4px !important;
  }
}
@media (min-width: 1920px) {
  .mr-xl-8 {
    margin-right: 8px !important;
  }
  .pr-xl-8 {
    padding-right: 8px !important;
  }
  .nmr-xl-8 {
    margin-right: -8px !important;
  }
}
@media (min-width: 1920px) {
  .mr-xl-12 {
    margin-right: 12px !important;
  }
  .pr-xl-12 {
    padding-right: 12px !important;
  }
  .nmr-xl-12 {
    margin-right: -12px !important;
  }
}
@media (min-width: 1920px) {
  .mr-xl-16 {
    margin-right: 16px !important;
  }
  .pr-xl-16 {
    padding-right: 16px !important;
  }
  .nmr-xl-16 {
    margin-right: -16px !important;
  }
}
@media (min-width: 1920px) {
  .mr-xl-20 {
    margin-right: 20px !important;
  }
  .pr-xl-20 {
    padding-right: 20px !important;
  }
  .nmr-xl-20 {
    margin-right: -20px !important;
  }
}
@media (min-width: 1920px) {
  .mr-xl-24 {
    margin-right: 24px !important;
  }
  .pr-xl-24 {
    padding-right: 24px !important;
  }
  .nmr-xl-24 {
    margin-right: -24px !important;
  }
}
@media (min-width: 1920px) {
  .mr-xl-32 {
    margin-right: 32px !important;
  }
  .pr-xl-32 {
    padding-right: 32px !important;
  }
  .nmr-xl-32 {
    margin-right: -32px !important;
  }
}
@media (min-width: 1920px) {
  .mr-xl-48 {
    margin-right: 48px !important;
  }
  .pr-xl-48 {
    padding-right: 48px !important;
  }
  .nmr-xl-48 {
    margin-right: -48px !important;
  }
}
@media (min-width: 1920px) {
  .mr-xl-64 {
    margin-right: 64px !important;
  }
  .pr-xl-64 {
    padding-right: 64px !important;
  }
  .nmr-xl-64 {
    margin-right: -64px !important;
  }
}
@media (min-width: 1920px) {
  .mx-xl-auto {
    margin-inline: auto !important;
  }
}
@media (min-width: 1920px) {
  .mx-xl-0 {
    margin-inline: 0px !important;
  }
  .px-xl-0 {
    padding-inline: 0px !important;
  }
}
@media (min-width: 1920px) {
  .mx-xl-4 {
    margin-inline: 4px !important;
  }
  .px-xl-4 {
    padding-inline: 4px !important;
  }
  .nmx-xl-4 {
    margin-inline: -4px !important;
  }
}
@media (min-width: 1920px) {
  .mx-xl-8 {
    margin-inline: 8px !important;
  }
  .px-xl-8 {
    padding-inline: 8px !important;
  }
  .nmx-xl-8 {
    margin-inline: -8px !important;
  }
}
@media (min-width: 1920px) {
  .mx-xl-12 {
    margin-inline: 12px !important;
  }
  .px-xl-12 {
    padding-inline: 12px !important;
  }
  .nmx-xl-12 {
    margin-inline: -12px !important;
  }
}
@media (min-width: 1920px) {
  .mx-xl-16 {
    margin-inline: 16px !important;
  }
  .px-xl-16 {
    padding-inline: 16px !important;
  }
  .nmx-xl-16 {
    margin-inline: -16px !important;
  }
}
@media (min-width: 1920px) {
  .mx-xl-20 {
    margin-inline: 20px !important;
  }
  .px-xl-20 {
    padding-inline: 20px !important;
  }
  .nmx-xl-20 {
    margin-inline: -20px !important;
  }
}
@media (min-width: 1920px) {
  .mx-xl-24 {
    margin-inline: 24px !important;
  }
  .px-xl-24 {
    padding-inline: 24px !important;
  }
  .nmx-xl-24 {
    margin-inline: -24px !important;
  }
}
@media (min-width: 1920px) {
  .mx-xl-32 {
    margin-inline: 32px !important;
  }
  .px-xl-32 {
    padding-inline: 32px !important;
  }
  .nmx-xl-32 {
    margin-inline: -32px !important;
  }
}
@media (min-width: 1920px) {
  .mx-xl-48 {
    margin-inline: 48px !important;
  }
  .px-xl-48 {
    padding-inline: 48px !important;
  }
  .nmx-xl-48 {
    margin-inline: -48px !important;
  }
}
@media (min-width: 1920px) {
  .mx-xl-64 {
    margin-inline: 64px !important;
  }
  .px-xl-64 {
    padding-inline: 64px !important;
  }
  .nmx-xl-64 {
    margin-inline: -64px !important;
  }
}
@media (min-width: 1920px) {
  .my-xl-auto {
    margin-block: auto !important;
  }
}
@media (min-width: 1920px) {
  .my-xl-0 {
    margin-block: 0px !important;
  }
  .py-xl-0 {
    padding-block: 0px !important;
  }
}
@media (min-width: 1920px) {
  .my-xl-4 {
    margin-block: 4px !important;
  }
  .py-xl-4 {
    padding-block: 4px !important;
  }
  .nmy-xl-4 {
    margin-block: -4px !important;
  }
}
@media (min-width: 1920px) {
  .my-xl-8 {
    margin-block: 8px !important;
  }
  .py-xl-8 {
    padding-block: 8px !important;
  }
  .nmy-xl-8 {
    margin-block: -8px !important;
  }
}
@media (min-width: 1920px) {
  .my-xl-12 {
    margin-block: 12px !important;
  }
  .py-xl-12 {
    padding-block: 12px !important;
  }
  .nmy-xl-12 {
    margin-block: -12px !important;
  }
}
@media (min-width: 1920px) {
  .my-xl-16 {
    margin-block: 16px !important;
  }
  .py-xl-16 {
    padding-block: 16px !important;
  }
  .nmy-xl-16 {
    margin-block: -16px !important;
  }
}
@media (min-width: 1920px) {
  .my-xl-20 {
    margin-block: 20px !important;
  }
  .py-xl-20 {
    padding-block: 20px !important;
  }
  .nmy-xl-20 {
    margin-block: -20px !important;
  }
}
@media (min-width: 1920px) {
  .my-xl-24 {
    margin-block: 24px !important;
  }
  .py-xl-24 {
    padding-block: 24px !important;
  }
  .nmy-xl-24 {
    margin-block: -24px !important;
  }
}
@media (min-width: 1920px) {
  .my-xl-32 {
    margin-block: 32px !important;
  }
  .py-xl-32 {
    padding-block: 32px !important;
  }
  .nmy-xl-32 {
    margin-block: -32px !important;
  }
}
@media (min-width: 1920px) {
  .my-xl-48 {
    margin-block: 48px !important;
  }
  .py-xl-48 {
    padding-block: 48px !important;
  }
  .nmy-xl-48 {
    margin-block: -48px !important;
  }
}
@media (min-width: 1920px) {
  .my-xl-64 {
    margin-block: 64px !important;
  }
  .py-xl-64 {
    padding-block: 64px !important;
  }
  .nmy-xl-64 {
    margin-block: -64px !important;
  }
}
/* read more: https://material.angular.io/guide/typography#using-typography-styles-in-your-application */
.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 700 clamp(1.5rem, 1.25rem + 1.25vw, 2rem) / 1 "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.0125em;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 700 clamp(1.25rem, 1.125rem + 0.625vw, 1.5rem) / 1.15 "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.01125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 700 clamp(1rem, 0.875rem + 0.625vw, 1.25rem) / 1.2 "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.01em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 1rem / 1.5 "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(1rem * 0.83) / 1.5 "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(1rem * 0.67) / 1.5 "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 700 clamp(0.875rem, 0.8125rem + 0.3125vw, 1rem) / 1.25 "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.0075em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 1rem / 1.5 "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 600 0.75rem / 1.5 "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

b,
.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extrabold {
  font-weight: 800 !important;
}

.font-size-base,
.font-size-default {
  font-size: 1rem !important;
}

.font-size-sm {
  font-size: 0.875rem !important;
}

.font-size-xs {
  font-size: 0.75rem !important;
}

.font-size-xxs {
  font-size: 0.625rem !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-nowrap-reset {
  white-space: normal !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

body.mat-typography .mat-h4,
body.mat-typography h4 {
  font-size: clamp(0.875rem, 0.8125rem + 0.3125vw, 1rem);
  font-weight: 700;
  line-height: 1.25;
  font-family: "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.0075em;
}

body.mat-typography .mat-h5,
body.mat-typography h5 {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25;
  margin: 0 0 8px;
}

body.mat-typography .mat-h6,
body.mat-typography h6 {
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: 0.48px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  margin: 0 0 8px;
}

body.mat-typography mat-error.mat-mdc-form-field-error,
body.mat-typography mat-hint.mat-mdc-form-field-hint {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5;
}

body.mat-typography mat-label,
body.mat-typography label.mat-label {
  font-weight: 700;
  font-size: 0.875rem;
}

body.mat-typography .mat-h1,
body.mat-typography h1,
body.mat-typography .mat-h2,
body.mat-typography h2,
body.mat-typography .mat-h3,
body.mat-typography h3,
body.mat-typography .mat-h4,
body.mat-typography h4,
body.mat-typography .mat-h5,
body.mat-typography h5,
body.mat-typography .mat-h6,
body.mat-typography h6,
body.mat-typography mat-label,
body.mat-typography label.mat-label {
  text-wrap: balance;
}

body.mat-typography mat-error.mat-mdc-form-field-error,
body.mat-typography mat-hint.mat-mdc-form-field-hint,
body.mat-typography p {
  text-wrap: pretty;
}

.text-monochrome-white {
  color: #fff !important;
}

.text-monochrome-400 {
  color: rgba(0, 0, 0, 0.16) !important;
}

.text-monochrome-500 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.text-monochrome-600 {
  color: rgba(0, 0, 0, 0.4) !important;
}

.text-monochrome-700 {
  color: rgba(0, 0, 0, 0.6) !important;
}

.text-monochrome-900 {
  color: #0f172a !important;
}

.text-monochrome-black {
  color: #000 !important;
}

.text-accent-500 {
  color: #3eb2a9 !important;
}

.text-danger-300 {
  color: #fca5a5 !important;
}

.text-danger-600 {
  color: #dc2626 !important;
}

.text-success-600 {
  color: #16a34a !important;
}

.text-info-700 {
  color: #0369a1 !important;
}

.text-warn-600 {
  color: #ca8a04 !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::after, *::before {
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

*:not(input):not(textarea) {
  user-select: none;
}

.jbd-card {
  box-shadow: none;
  border-radius: 8px;
  padding: 24px 20px;
  background-color: #fff;
}

hr {
  margin-block: 24px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}

mat-divider.mat-divider.mat-divider-vertical {
  border-right-width: 2px;
  border-color: rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}

a {
  color: #0369a1;
  font-weight: 600;
}

mat-icon.mat-icon {
  width: auto !important;
  overflow: initial;
}

ul.simple-list {
  list-style-position: outside;
  padding-left: 16px;
}
ul.simple-list.has-grey-dots li::marker {
  color: rgba(0, 0, 0, 0.4);
  padding-left: 4px;
}
ul.simple-list.has-custom-marker {
  list-style: none;
  padding-left: 0;
}
ul.simple-list.has-custom-marker li {
  display: flex;
  gap: 8px;
}
ul.simple-list.has-custom-marker li > .custom-marker {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.75rem;
  transform: translateY(3px);
}
ul.simple-list.has-custom-marker li:not(:last-of-type) {
  margin-bottom: 4px;
}

.jbd-paginator {
  --mat-icon-button-touch-target-display: none;
}
.jbd-paginator .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.jbd-paginator .mat-mdc-paginator-range-label {
  font-size: 1rem;
  font-weight: 600;
  color: #0f172a;
  margin-inline: 12px;
}
.jbd-paginator .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.4) !important;
  opacity: 1;
  transition: opacity 0.25s ease-out;
}
.jbd-paginator .mat-mdc-icon-button.mat-mdc-button-base[disabled] .mat-mdc-paginator-icon {
  opacity: 0.4;
}

/**
  SHARED STYLES
 */
.jbd-table {
  width: 100%;
}

.jbd-table thead tr.mat-mdc-header-row {
  height: 48px;
}
.jbd-table thead tr.mat-mdc-header-row th.mat-mdc-header-cell {
  padding-inline: 4px;
}
.jbd-table thead tr.mat-mdc-header-row th.mat-mdc-header-cell:first-of-type {
  padding-inline-start: 16px;
}
.jbd-table thead tr.mat-mdc-header-row th.mat-mdc-header-cell:last-of-type {
  padding-inline-end: 16px;
}
.jbd-table thead tr.mat-mdc-header-row th.mat-mdc-header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.16);
  border-bottom-width: 2px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  font-weight: 600;
}

.jbd-table tbody tr.mat-mdc-row {
  vertical-align: top;
}
.jbd-table tbody tr.mat-mdc-row td.mat-mdc-cell {
  color: #0f172a;
  font-size: 0.875rem;
  font-weight: 400;
}

/**
  SIMPLE TABLE STYLES
 */
.jbd-table:not(.is-expandable) tbody tr.mat-mdc-row td.mat-mdc-cell {
  padding: 14px 4px;
}
.jbd-table:not(.is-expandable) tbody tr.mat-mdc-row td.mat-mdc-cell:first-of-type {
  padding-inline-start: 16px;
}
.jbd-table:not(.is-expandable) tbody tr.mat-mdc-row td.mat-mdc-cell:last-of-type {
  padding-inline-end: 16px;
}
.jbd-table:not(.is-expandable) tbody tr.mat-mdc-row td.mat-mdc-cell.mat-column-actions {
  padding-block: 4px;
  text-align: right;
}
.jbd-table:not(.is-expandable) tbody tr.mat-mdc-row td.mat-mdc-cell {
  border-bottom: 2px solid rgba(0, 0, 0, 0.04);
}

/**
  EXPANDABLE TABLE STYLES
 */
.jbd-table.is-expandable tbody tr.mat-mdc-row.main-row td.mat-mdc-cell {
  cursor: pointer;
  padding: 14px 4px;
}
.jbd-table.is-expandable tbody tr.mat-mdc-row.main-row td.mat-mdc-cell:first-of-type {
  padding-inline-start: 16px;
}
.jbd-table.is-expandable tbody tr.mat-mdc-row.main-row td.mat-mdc-cell:last-of-type {
  padding-inline-end: 16px;
}
.jbd-table.is-expandable tbody tr.mat-mdc-row.main-row td.mat-mdc-cell.mat-column-actions {
  padding-block: 4px;
  text-align: right;
}
.jbd-table.is-expandable tbody tr.mat-mdc-row.detail-row {
  height: 0;
}
.jbd-table.is-expandable tbody tr.mat-mdc-row.detail-row td.mat-mdc-cell.mat-column-expanded-detail {
  padding: 0;
}
.jbd-table.is-expandable tbody tr.mat-mdc-row.detail-row td.mat-mdc-cell.mat-column-expanded-detail .element-detail {
  padding: 0 16px 14px;
}
.jbd-table.is-expandable tbody tr.mat-mdc-row.main-row td.mat-mdc-cell {
  border-bottom: 2px solid rgba(0, 0, 0, 0.04);
}
.jbd-table.is-expandable tbody tr.mat-mdc-row.main-row.child-is-expanded td.mat-mdc-cell {
  border-bottom-color: transparent;
}
.jbd-table.is-expandable tbody tr.mat-mdc-row.detail-row td.mat-mdc-cell.mat-column-expanded-detail {
  border-bottom: none;
}
.jbd-table.is-expandable tbody tr.mat-mdc-row.detail-row td.mat-mdc-cell.mat-column-expanded-detail .element-detail {
  border-bottom: 2px solid rgba(0, 0, 0, 0.04);
}
.jbd-table.is-expandable tbody tr.mat-mdc-row.main-row:hover, .jbd-table.is-expandable tbody tr.mat-mdc-row.main-row:active {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-tooltip-trigger.has-help-cursor,
.mat-mdc-tooltip-trigger.has-help-cursor * {
  cursor: help !important;
}

.mat-mdc-tooltip.mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-size: 10px;
  --mdc-plain-tooltip-supporting-text-tracking: 0.01em;
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-fluid,
.fixed-width-container-sm,
.fixed-width-container-md,
.fixed-width-container-lg,
.fixed-width-container-xl {
  width: 100%;
  margin-inline: auto;
}

@media (min-width: 600px) {
  .container,
  .container-sm,
  .fixed-width-container-sm {
    max-width: 500px;
  }
}

@media (min-width: 960px) {
  .container,
  .container-sm,
  .container-md,
  .fixed-width-container-md {
    max-width: 800px;
  }
}

@media (min-width: 1280px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .fixed-width-container-lg {
    max-width: 1200px;
  }
}

@media (min-width: 1920px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .fixed-width-container-xl {
    max-width: 1600px;
  }
}

html {
  min-height: 100%;
  display: flex;
}

body {
  min-height: 100%;
  flex: 1;
  background-color: white;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #f7e383;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #3eb2a9;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #f7e383;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f7e383;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #3eb2a9;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3eb2a9;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #3eb2a9;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3eb2a9;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-option-label-text-line-height: 1.5;
  --mat-option-label-text-size: 1rem;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-optgroup-label-text-line-height: 1.5;
  --mat-optgroup-label-text-size: 1rem;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-card-title-text-line-height: 1.15;
  --mat-card-title-text-size: clamp(1.25rem, 1.125rem + 0.625vw, 1.5rem);
  --mat-card-title-text-tracking: -0.01125em;
  --mat-card-title-text-weight: 700;
  --mat-card-subtitle-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-card-subtitle-text-line-height: 1.25;
  --mat-card-subtitle-text-size: clamp(0.875rem, 0.8125rem + 0.3125vw, 1rem);
  --mat-card-subtitle-text-tracking: -0.0075em;
  --mat-card-subtitle-text-weight: 700;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f7e383;
  --mdc-linear-progress-track-color: rgba(247, 227, 131, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #3eb2a9;
  --mdc-linear-progress-track-color: rgba(62, 178, 169, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 600;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #f7e383;
  --mdc-filled-text-field-focus-active-indicator-color: #f7e383;
  --mdc-filled-text-field-focus-label-text-color: rgba(247, 227, 131, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #f7e383;
  --mdc-outlined-text-field-focus-outline-color: #f7e383;
  --mdc-outlined-text-field-focus-label-text-color: rgba(247, 227, 131, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(247, 227, 131, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #3eb2a9;
  --mdc-filled-text-field-focus-active-indicator-color: #3eb2a9;
  --mdc-filled-text-field-focus-label-text-color: rgba(62, 178, 169, 0.87);
  --mdc-outlined-text-field-caret-color: #3eb2a9;
  --mdc-outlined-text-field-focus-outline-color: #3eb2a9;
  --mdc-outlined-text-field-focus-label-text-color: rgba(62, 178, 169, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(62, 178, 169, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-filled-text-field-label-text-size: 1rem;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-form-field-container-text-line-height: 1.5;
  --mat-form-field-container-text-size: 1rem;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 1rem;
  --mat-form-field-subscript-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-form-field-subscript-text-line-height: 1.5;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 600;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(247, 227, 131, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(62, 178, 169, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-select-trigger-text-line-height: 1.5;
  --mat-select-trigger-text-size: 1rem;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-dialog-subhead-line-height: 1.15;
  --mdc-dialog-subhead-size: clamp(1.25rem, 1.125rem + 0.625vw, 1.5rem);
  --mdc-dialog-subhead-weight: 700;
  --mdc-dialog-subhead-tracking: -0.01125em;
  --mdc-dialog-supporting-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-dialog-supporting-text-line-height: 1.5;
  --mdc-dialog-supporting-text-size: 1rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-elevated-container-color: #f7e383;
  --mdc-chip-elevated-selected-container-color: #f7e383;
  --mdc-chip-elevated-disabled-container-color: #f7e383;
  --mdc-chip-flat-disabled-selected-container-color: #f7e383;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: black;
  --mdc-chip-selected-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
  --mat-chip-selected-disabled-trailing-icon-color: black;
  --mat-chip-selected-trailing-icon-color: black;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-elevated-container-color: #3eb2a9;
  --mdc-chip-elevated-selected-container-color: #3eb2a9;
  --mdc-chip-elevated-disabled-container-color: #3eb2a9;
  --mdc-chip-flat-disabled-selected-container-color: #3eb2a9;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: black;
  --mdc-chip-selected-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
  --mat-chip-selected-disabled-trailing-icon-color: black;
  --mat-chip-selected-trailing-icon-color: black;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-chip-label-text-line-height: 1.5;
  --mdc-chip-label-text-size: 1rem;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #f7df63;
  --mdc-switch-selected-handle-color: #f7df63;
  --mdc-switch-selected-hover-state-layer-color: #f7df63;
  --mdc-switch-selected-pressed-state-layer-color: #f7df63;
  --mdc-switch-selected-focus-handle-color: #b39812;
  --mdc-switch-selected-hover-handle-color: #b39812;
  --mdc-switch-selected-pressed-handle-color: #b39812;
  --mdc-switch-selected-focus-track-color: #fff4bf;
  --mdc-switch-selected-hover-track-color: #fff4bf;
  --mdc-switch-selected-pressed-track-color: #fff4bf;
  --mdc-switch-selected-track-color: #fff4bf;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #00aa9e;
  --mdc-switch-selected-handle-color: #00aa9e;
  --mdc-switch-selected-hover-state-layer-color: #00aa9e;
  --mdc-switch-selected-pressed-state-layer-color: #00aa9e;
  --mdc-switch-selected-focus-handle-color: #00615a;
  --mdc-switch-selected-hover-handle-color: #00615a;
  --mdc-switch-selected-pressed-handle-color: #00615a;
  --mdc-switch-selected-focus-track-color: #a0ded9;
  --mdc-switch-selected-hover-track-color: #a0ded9;
  --mdc-switch-selected-pressed-track-color: #a0ded9;
  --mdc-switch-selected-track-color: #a0ded9;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-switch-label-text-line-height: 1.5;
  --mat-switch-label-text-size: 1rem;
  --mat-switch-label-text-tracking: normal;
  --mat-switch-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f7e383;
  --mdc-radio-selected-hover-icon-color: #f7e383;
  --mdc-radio-selected-icon-color: #f7e383;
  --mdc-radio-selected-pressed-icon-color: #f7e383;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f7e383;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3eb2a9;
  --mdc-radio-selected-hover-icon-color: #3eb2a9;
  --mdc-radio-selected-icon-color: #3eb2a9;
  --mdc-radio-selected-pressed-icon-color: #3eb2a9;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #3eb2a9;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-radio-label-text-line-height: 1.5;
  --mat-radio-label-text-size: 1rem;
  --mat-radio-label-text-tracking: normal;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #f7e383;
  --mdc-slider-focus-handle-color: #f7e383;
  --mdc-slider-hover-handle-color: #f7e383;
  --mdc-slider-active-track-color: #f7e383;
  --mdc-slider-inactive-track-color: #f7e383;
  --mdc-slider-with-tick-marks-inactive-container-color: #f7e383;
  --mdc-slider-with-tick-marks-active-container-color: black;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #f7e383;
  --mat-slider-hover-state-layer-color: rgba(247, 227, 131, 0.05);
  --mat-slider-focus-state-layer-color: rgba(247, 227, 131, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #3eb2a9;
  --mdc-slider-focus-handle-color: #3eb2a9;
  --mdc-slider-hover-handle-color: #3eb2a9;
  --mdc-slider-active-track-color: #3eb2a9;
  --mdc-slider-inactive-track-color: #3eb2a9;
  --mdc-slider-with-tick-marks-inactive-container-color: #3eb2a9;
  --mdc-slider-with-tick-marks-active-container-color: black;
  --mat-slider-ripple-color: #3eb2a9;
  --mat-slider-hover-state-layer-color: rgba(62, 178, 169, 0.05);
  --mat-slider-focus-state-layer-color: rgba(62, 178, 169, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
}

html {
  --mdc-slider-label-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-slider-label-label-text-size: clamp(0.875rem, 0.8125rem + 0.3125vw, 1rem);
  --mdc-slider-label-label-text-line-height: 1.25;
  --mdc-slider-label-label-text-tracking: -0.0075em;
  --mdc-slider-label-label-text-weight: 700;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-menu-item-label-text-size: 1rem;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 1.5;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f7e383;
  --mdc-radio-selected-hover-icon-color: #f7e383;
  --mdc-radio-selected-icon-color: #f7e383;
  --mdc-radio-selected-pressed-icon-color: #f7e383;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3eb2a9;
  --mdc-radio-selected-hover-icon-color: #3eb2a9;
  --mdc-radio-selected-icon-color: #3eb2a9;
  --mdc-radio-selected-pressed-icon-color: #3eb2a9;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #f7e383;
  --mdc-checkbox-selected-hover-icon-color: #f7e383;
  --mdc-checkbox-selected-icon-color: #f7e383;
  --mdc-checkbox-selected-pressed-icon-color: #f7e383;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f7e383;
  --mdc-checkbox-selected-hover-state-layer-color: #f7e383;
  --mdc-checkbox-selected-pressed-state-layer-color: #f7e383;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #3eb2a9;
  --mdc-checkbox-selected-hover-icon-color: #3eb2a9;
  --mdc-checkbox-selected-icon-color: #3eb2a9;
  --mdc-checkbox-selected-pressed-icon-color: #3eb2a9;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3eb2a9;
  --mdc-checkbox-selected-hover-state-layer-color: #3eb2a9;
  --mdc-checkbox-selected-pressed-state-layer-color: #3eb2a9;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #f7e383;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-list-list-item-label-text-line-height: 1.5;
  --mdc-list-list-item-label-text-size: 1rem;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-list-list-item-supporting-text-line-height: 1.5;
  --mdc-list-list-item-supporting-text-size: 1rem;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1.5;
  --mdc-list-list-item-trailing-supporting-text-size: 0.75rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 600;
}

.mdc-list-group__subheader {
  font: 700 clamp(1rem, 0.875rem + 0.625vw, 1.25rem) / 1.2 "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.01em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mat-paginator-container-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-paginator-container-text-line-height: 1.5;
  --mat-paginator-container-text-size: 0.75rem;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 600;
  --mat-paginator-select-trigger-text-size: 0.75rem;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #f7e383;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f7e383;
  --mat-tab-header-active-ripple-color: #f7e383;
  --mat-tab-header-inactive-ripple-color: #f7e383;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f7e383;
  --mat-tab-header-active-hover-label-text-color: #f7e383;
  --mat-tab-header-active-focus-indicator-color: #f7e383;
  --mat-tab-header-active-hover-indicator-color: #f7e383;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #3eb2a9;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3eb2a9;
  --mat-tab-header-active-ripple-color: #3eb2a9;
  --mat-tab-header-inactive-ripple-color: #3eb2a9;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3eb2a9;
  --mat-tab-header-active-hover-label-text-color: #3eb2a9;
  --mat-tab-header-active-focus-indicator-color: #3eb2a9;
  --mat-tab-header-active-hover-indicator-color: #3eb2a9;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #f7e383;
  --mat-tab-header-with-background-foreground-color: black;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #3eb2a9;
  --mat-tab-header-with-background-foreground-color: black;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #3eb2a9;
  --mdc-checkbox-selected-hover-icon-color: #3eb2a9;
  --mdc-checkbox-selected-icon-color: #3eb2a9;
  --mdc-checkbox-selected-pressed-icon-color: #3eb2a9;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3eb2a9;
  --mdc-checkbox-selected-hover-state-layer-color: #3eb2a9;
  --mdc-checkbox-selected-pressed-state-layer-color: #3eb2a9;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #f7e383;
  --mdc-checkbox-selected-hover-icon-color: #f7e383;
  --mdc-checkbox-selected-icon-color: #f7e383;
  --mdc-checkbox-selected-pressed-icon-color: #f7e383;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f7e383;
  --mdc-checkbox-selected-hover-state-layer-color: #f7e383;
  --mdc-checkbox-selected-pressed-state-layer-color: #f7e383;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-checkbox-label-text-line-height: 1.5;
  --mat-checkbox-label-text-size: 1rem;
  --mat-checkbox-label-text-tracking: normal;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f7e383;
  --mat-text-button-state-layer-color: #f7e383;
  --mat-text-button-ripple-color: rgba(247, 227, 131, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #3eb2a9;
  --mat-text-button-state-layer-color: #3eb2a9;
  --mat-text-button-ripple-color: rgba(62, 178, 169, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f7e383;
  --mdc-filled-button-label-text-color: black;
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #3eb2a9;
  --mdc-filled-button-label-text-color: black;
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f7e383;
  --mdc-protected-button-label-text-color: black;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #3eb2a9;
  --mdc-protected-button-label-text-color: black;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f7e383;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f7e383;
  --mat-outlined-button-ripple-color: rgba(247, 227, 131, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #3eb2a9;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #3eb2a9;
  --mat-outlined-button-ripple-color: rgba(62, 178, 169, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f7e383;
  --mat-icon-button-state-layer-color: #f7e383;
  --mat-icon-button-ripple-color: rgba(247, 227, 131, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #3eb2a9;
  --mat-icon-button-state-layer-color: #3eb2a9;
  --mat-icon-button-ripple-color: rgba(62, 178, 169, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #f7e383;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #3eb2a9;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #f7e383;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #3eb2a9;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #3eb2a9;
}

html {
  --mdc-snackbar-supporting-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-snackbar-supporting-text-line-height: 1.5;
  --mdc-snackbar-supporting-text-size: 1rem;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-table-header-headline-line-height: 1.25;
  --mat-table-header-headline-size: clamp(0.875rem, 0.8125rem + 0.3125vw, 1rem);
  --mat-table-header-headline-weight: 700;
  --mat-table-header-headline-tracking: -0.0075em;
  --mat-table-row-item-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-table-row-item-label-text-line-height: 1.5;
  --mat-table-row-item-label-text-size: 1rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-table-footer-supporting-text-line-height: 1.5;
  --mat-table-footer-supporting-text-size: 1rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #f7e383;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #3eb2a9;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #f7e383;
  --mat-badge-text-color: black;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #3eb2a9;
  --mat-badge-text-color: black;
}

.mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-bottom-sheet-container-text-line-height: 1.5;
  --mat-bottom-sheet-container-text-size: 1rem;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-legacy-button-toggle-label-text-line-height: 1.5;
  --mat-legacy-button-toggle-label-text-size: 1rem;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-standard-button-toggle-label-text-line-height: 1.5;
  --mat-standard-button-toggle-label-text-size: 1rem;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: #f7e383;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(247, 227, 131, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(247, 227, 131, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(247, 227, 131, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #f7e383;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(247, 227, 131, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: #3eb2a9;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(62, 178, 169, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(62, 178, 169, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(62, 178, 169, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(62, 178, 169, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #3eb2a9;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-expansion-header-text-size: clamp(0.875rem, 0.8125rem + 0.3125vw, 1rem);
  --mat-expansion-header-text-weight: 700;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-expansion-container-text-line-height: 1.5;
  --mat-expansion-container-text-size: 1rem;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 1rem;
  --mat-grid-list-tile-header-secondary-text-size: 0.75rem;
  --mat-grid-list-tile-footer-primary-text-size: 1rem;
  --mat-grid-list-tile-footer-secondary-text-size: 0.75rem;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #f7e383;
}
.mat-icon.mat-accent {
  --mat-icon-color: #3eb2a9;
}
.mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: black;
  --mat-stepper-header-selected-state-icon-background-color: #f7e383;
  --mat-stepper-header-selected-state-icon-foreground-color: black;
  --mat-stepper-header-done-state-icon-background-color: #f7e383;
  --mat-stepper-header-done-state-icon-foreground-color: black;
  --mat-stepper-header-edit-state-icon-background-color: #f7e383;
  --mat-stepper-header-edit-state-icon-foreground-color: black;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: black;
  --mat-stepper-header-selected-state-icon-background-color: #3eb2a9;
  --mat-stepper-header-selected-state-icon-foreground-color: black;
  --mat-stepper-header-done-state-icon-background-color: #3eb2a9;
  --mat-stepper-header-done-state-icon-foreground-color: black;
  --mat-stepper-header-edit-state-icon-background-color: #3eb2a9;
  --mat-stepper-header-edit-state-icon-foreground-color: black;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-stepper-header-label-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-stepper-header-label-text-size: 1rem;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 1rem;
  --mat-stepper-header-selected-state-label-text-size: 1rem;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #f7e383;
  --mat-toolbar-container-text-color: black;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #3eb2a9;
  --mat-toolbar-container-text-color: black;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-toolbar-title-text-line-height: 1.15;
  --mat-toolbar-title-text-size: clamp(1.25rem, 1.125rem + 0.625vw, 1.5rem);
  --mat-toolbar-title-text-tracking: -0.01125em;
  --mat-toolbar-title-text-weight: 700;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-tree-node-text-size: 1rem;
  --mat-tree-node-text-weight: 400;
}

:root {
  --mdc-text-button-label-text-tracking: 0px;
  --mdc-outlined-button-label-text-tracking: 0px;
  --mdc-filled-button-label-text-tracking: 0px;
}

jbd-ui-button-primary .mdc-button__label,
jbd-ui-button-secondary .mdc-button__label,
jbd-ui-button-tertiary .mdc-button__label {
  flex: 1;
}

jbd-ui-chip .mat-mdc-chip {
  --mdc-chip-elevated-container-color: rgba(0, 0, 0, 0.08);
  --mdc-chip-label-text-color: #0f172a;
  --mdc-chip-disabled-label-text-color: var(--mdc-chip-label-text-color);
  --mdc-chip-label-text-size: 0.625rem;
  --mdc-chip-label-text-weight: 600;
  --mdc-chip-container-shape-radius: 8px;
  --mdc-chip-label-text-tracking: 0.25px;
  --mdc-chip-container-height: auto !important;
  line-height: 1;
  min-height: revert;
  padding: 3px 8px;
}
jbd-ui-chip .mat-mdc-chip .mat-mdc-chip-action {
  padding-inline: 0;
}

jbd-ui-chip .mat-mdc-chip.is-success {
  --mdc-chip-elevated-container-color: #bbf7d0;
}
jbd-ui-chip .mat-mdc-chip.is-warn {
  --mdc-chip-elevated-container-color: #fef9c3;
}
jbd-ui-chip .mat-mdc-chip.is-danger {
  --mdc-chip-elevated-container-color: #fee2e2;
}
jbd-ui-chip .mat-mdc-chip.is-info {
  --mdc-chip-elevated-container-color: #e0f2fe;
}
jbd-ui-chip .mat-mdc-chip.is-accent {
  --mdc-chip-elevated-container-color: #a0ded9;
}

jbd-ui-chip .mat-mdc-chip:not(.is-clickable):not(.mdc-evolution-chip--disabled):active {
  box-shadow: none;
}
jbd-ui-chip .mat-mdc-chip:not(.is-clickable):not(.mdc-evolution-chip--disabled)::after {
  background: none;
}

.cdk-overlay-pane.dialog-is-lg, .cdk-overlay-pane.dialog-is-default, .cdk-overlay-pane.dialog-is-sm {
  position: absolute !important;
  top: 0;
  margin-block: 80px;
}
@media (min-width: 0) and (max-width: 599.9px) {
  .cdk-overlay-pane.dialog-is-lg, .cdk-overlay-pane.dialog-is-default, .cdk-overlay-pane.dialog-is-sm {
    margin-block: 50px;
  }
}
.cdk-overlay-pane.dialog-is-lg .mat-mdc-dialog-container .mdc-dialog__content, .cdk-overlay-pane.dialog-is-default .mat-mdc-dialog-container .mdc-dialog__content, .cdk-overlay-pane.dialog-is-sm .mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-color: #0f172a;
}
.cdk-overlay-pane.dialog-is-sm {
  width: 300px;
}
.cdk-overlay-pane.dialog-is-sm mat-dialog-container.mat-mdc-dialog-container .mdc-dialog__surface {
  --mdc-dialog-container-shape: 8px;
}
.cdk-overlay-pane.dialog-is-default {
  width: 500px;
}
.cdk-overlay-pane.dialog-is-default mat-dialog-container.mat-mdc-dialog-container .mdc-dialog__surface {
  --mdc-dialog-container-shape: 16px;
}
.cdk-overlay-pane.dialog-is-lg {
  width: 960px;
}
.cdk-overlay-pane.dialog-is-lg mat-dialog-container.mat-mdc-dialog-container .mdc-dialog__surface {
  --mdc-dialog-container-shape: 16px;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.cdk-overlay-pane.dialog-is-sm .mat-mdc-dialog-title, .cdk-overlay-pane.dialog-is-default .mat-mdc-dialog-title, .cdk-overlay-pane.dialog-is-lg .mat-mdc-dialog-title {
  position: relative;
}
.cdk-overlay-pane.dialog-is-sm .mat-mdc-dialog-title::before, .cdk-overlay-pane.dialog-is-default .mat-mdc-dialog-title::before, .cdk-overlay-pane.dialog-is-lg .mat-mdc-dialog-title::before {
  display: none;
}
.cdk-overlay-pane.dialog-is-sm .mat-mdc-dialog-title .mat-dialog-title-inner.hide-close-btn, .cdk-overlay-pane.dialog-is-default .mat-mdc-dialog-title .mat-dialog-title-inner.hide-close-btn, .cdk-overlay-pane.dialog-is-lg .mat-mdc-dialog-title .mat-dialog-title-inner.hide-close-btn {
  width: 100%;
  height: 100%;
}
.cdk-overlay-pane.dialog-is-sm .mat-mdc-dialog-title .mat-dialog-title-inner jbd-ui-icon-button, .cdk-overlay-pane.dialog-is-default .mat-mdc-dialog-title .mat-dialog-title-inner jbd-ui-icon-button, .cdk-overlay-pane.dialog-is-lg .mat-mdc-dialog-title .mat-dialog-title-inner jbd-ui-icon-button {
  position: absolute;
}
.cdk-overlay-pane.dialog-is-sm .mat-mdc-dialog-title {
  height: 56px;
}
.cdk-overlay-pane.dialog-is-sm .mat-mdc-dialog-title .mat-dialog-title-inner {
  width: calc(100% - 36px - 10px);
  height: 100%;
}
.cdk-overlay-pane.dialog-is-sm .mat-mdc-dialog-title .mat-dialog-title-inner jbd-ui-icon-button {
  top: 10px;
  right: 10px;
}
.cdk-overlay-pane.dialog-is-default .mat-mdc-dialog-title, .cdk-overlay-pane.dialog-is-lg .mat-mdc-dialog-title {
  height: 88px;
}
.cdk-overlay-pane.dialog-is-default .mat-mdc-dialog-title .mat-dialog-title-inner, .cdk-overlay-pane.dialog-is-lg .mat-mdc-dialog-title .mat-dialog-title-inner {
  width: calc(100% - 40px - 16px);
  height: 100%;
}
.cdk-overlay-pane.dialog-is-default .mat-mdc-dialog-title .mat-dialog-title-inner jbd-ui-icon-button, .cdk-overlay-pane.dialog-is-lg .mat-mdc-dialog-title .mat-dialog-title-inner jbd-ui-icon-button {
  top: 24px;
  right: 16px;
}

.cdk-overlay-pane .mat-mdc-dialog-container .mat-mdc-dialog-content {
  --mdc-dialog-supporting-text-color: initial;
  margin: 0;
  overscroll-behavior-y: contain;
  overflow-y: auto;
  background: linear-gradient(#f5f7f7 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #f5f7f7 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-color: #f5f7f7;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  /* enhances overflow Scrolling on ios */
  -webkit-overflow-scrolling: touch;
}
@media screen and (orientation: landscape) {
  .cdk-overlay-pane .mat-mdc-dialog-container .mat-mdc-dialog-content {
    max-height: calc(calc(100vh - 160px - 88px) - 86px);
  }
  .cdk-overlay-pane .mat-mdc-dialog-container .mat-mdc-dialog-content.dialog-has-no-footer-action-row {
    max-height: calc(100vh - 160px - 88px);
  }
}
@media screen and (orientation: landscape) and (max-width: 599.9px) {
  .cdk-overlay-pane .mat-mdc-dialog-container .mat-mdc-dialog-content {
    max-height: calc(calc(100vh - 100px - 88px - 86px) - 86px);
  }
  .cdk-overlay-pane .mat-mdc-dialog-container .mat-mdc-dialog-content.dialog-has-no-footer-action-row {
    max-height: calc(100vh - 100px - 88px - 86px);
  }
}
@media screen and (orientation: portrait) {
  .cdk-overlay-pane .mat-mdc-dialog-container .mat-mdc-dialog-content {
    max-height: calc(calc(100vh - 160px - 88px) - 86px);
  }
  .cdk-overlay-pane .mat-mdc-dialog-container .mat-mdc-dialog-content.dialog-has-no-footer-action-row {
    max-height: calc(100vh - 160px - 88px);
  }
}
@media screen and (orientation: portrait) and (max-width: 599.9px) {
  .cdk-overlay-pane .mat-mdc-dialog-container .mat-mdc-dialog-content {
    max-height: calc(calc(100vh - 100px - 88px) - 86px);
  }
  .cdk-overlay-pane .mat-mdc-dialog-container .mat-mdc-dialog-content.dialog-has-no-footer-action-row {
    max-height: calc(100vh - 100px - 88px);
  }
}
.cdk-overlay-pane.dialog-is-sm .mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding: 16px 16px;
}
.cdk-overlay-pane.dialog-is-default .mat-mdc-dialog-container .mat-mdc-dialog-content, .cdk-overlay-pane.dialog-is-lg .mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding: 20px 16px;
}

.cdk-overlay-pane .mat-mdc-dialog-container footer.mat-mdc-dialog-actions {
  margin-bottom: 0;
  min-height: revert;
}
.cdk-overlay-pane.dialog-is-sm .mat-mdc-dialog-container footer.mat-mdc-dialog-actions {
  padding: 16px 16px;
}
.cdk-overlay-pane.dialog-is-default .mat-mdc-dialog-container footer.mat-mdc-dialog-actions, .cdk-overlay-pane.dialog-is-lg .mat-mdc-dialog-container footer.mat-mdc-dialog-actions {
  border-top: 2px solid rgba(0, 0, 0, 0.16);
  padding: 20px 16px;
}

form,
.jbd-form {
  transition: opacity 0.25s ease-out;
  opacity: 1;
}
form.is-disabled,
.jbd-form.is-disabled {
  opacity: 0.4;
  transition: opacity 0.25s ease-out;
  cursor: wait;
}
form.is-disabled *,
.jbd-form.is-disabled * {
  pointer-events: none;
}

jbd-ui-form-autocomplete .mat-autocomplete-list-is-open mat-form-field.mat-mdc-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
jbd-ui-form-autocomplete .mat-autocomplete-list-is-open mat-form-field.mat-mdc-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
jbd-ui-form-autocomplete .mat-autocomplete-list-is-open mat-form-field.mat-mdc-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.mat-mdc-autocomplete-panel.mdc-menu-surface.mdc-menu-surface--open.mat-mdc-autocomplete-visible {
  background: linear-gradient(#fff 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  /* enhances overflow Scrolling on ios */
  -webkit-overflow-scrolling: touch;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-top-width: 0;
  box-shadow: none;
  padding-block: 0;
}
.mat-mdc-autocomplete-panel.mdc-menu-surface.mdc-menu-surface--open.mat-mdc-autocomplete-visible .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-mdc-autocomplete-panel.mdc-menu-surface.mdc-menu-surface--open.mat-mdc-autocomplete-visible .mat-mdc-option.mat-mdc-option-active {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-mdc-autocomplete-panel.mdc-menu-surface.mdc-menu-surface--open.mat-mdc-autocomplete-visible .mat-mdc-option.mat-mdc-option-active:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.mat-mdc-autocomplete-panel.mdc-menu-surface.mdc-menu-surface--open.mat-mdc-autocomplete-visible .mat-mdc-option .mdc-list-item__primary-text {
  font-size: 1rem;
  font-weight: 600;
  color: #0f172a;
}

mat-checkbox.mat-mdc-checkbox .mdc-checkbox {
  --mdc-checkbox-state-layer-size: 36px;
  --mat-checkbox-touch-target-display: block;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.16);
  --mdc-checkbox-unselected-focus-icon-color: var(
    --mdc-checkbox-unselected-icon-color
  );
  --mdc-checkbox-unselected-hover-icon-color: var(
    --mdc-checkbox-unselected-icon-color
  );
  --mdc-checkbox-unselected-pressed-icon-color: var(
    --mdc-checkbox-unselected-icon-color
  );
}
mat-checkbox.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background,
mat-checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  background-color: #fff;
}

.mat-form-field-checkbox mat-checkbox.mat-mdc-checkbox {
  display: block;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.mat-form-field-checkbox mat-checkbox.mat-mdc-checkbox .mdc-form-field {
  display: flex;
  align-items: flex-start;
}
.mat-form-field-checkbox mat-checkbox.mat-mdc-checkbox .mdc-form-field label[for^=mat-mdc-checkbox] {
  display: flex;
  width: 100%;
}
.mat-form-field-checkbox mat-checkbox.mat-mdc-checkbox .mdc-form-field label[for^=mat-mdc-checkbox] .mat-checkbox-inline-label {
  pointer-events: auto;
  white-space: pre-wrap;
  font-size: 0.875rem;
  line-height: 1.5;
}

.mat-form-field-checkbox mat-checkbox.mat-mdc-checkbox.mat-mdc-checkbox-disabled {
  cursor: not-allowed !important;
  pointer-events: unset;
}
.mat-form-field-checkbox mat-checkbox.mat-mdc-checkbox.mat-mdc-checkbox-disabled * {
  pointer-events: none !important;
}
.mat-form-field-checkbox mat-checkbox.mat-mdc-checkbox.mat-mdc-checkbox-disabled .mat-checkbox-inline-label a {
  color: inherit;
}

.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content {
  margin-block: 8px;
}
.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget {
  width: 278px !important;
}

.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content,
.cdk-overlay-pane.mat-datepicker-dialog .mat-datepicker-content {
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: none;
}
.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-period-button,
.cdk-overlay-pane.mat-datepicker-dialog .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-period-button {
  color: #0f172a;
  font-weight: 700;
}
.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-table-header th,
.cdk-overlay-pane.mat-datepicker-dialog .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-table-header th {
  font-weight: 700;
}
.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-table-header .mat-calendar-table-header-divider::after,
.cdk-overlay-pane.mat-datepicker-dialog .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-table-header .mat-calendar-table-header-divider::after {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.08);
}
.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body .mat-calendar-body-label,
.cdk-overlay-pane.mat-datepicker-dialog .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body .mat-calendar-body-label {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.4);
}
.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body .mat-calendar-body-cell-content,
.cdk-overlay-pane.mat-datepicker-dialog .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body .mat-calendar-body-cell-content {
  border-width: 1px;
}
.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body .mat-calendar-body-cell-content.mat-calendar-body-today:not(.mat-calendar-body-selected),
.cdk-overlay-pane.mat-datepicker-dialog .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body .mat-calendar-body-cell-content.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: transparent;
}
.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body .mat-calendar-body-cell-content.mat-calendar-body-today.mat-calendar-body-selected,
.cdk-overlay-pane.mat-datepicker-dialog .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body .mat-calendar-body-cell-content.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none;
}
.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body td button.mat-calendar-body-cell,
.cdk-overlay-pane.mat-datepicker-dialog .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body td button.mat-calendar-body-cell {
  font-weight: 600;
}
.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body td button.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-overlay-pane.mat-datepicker-dialog .mat-datepicker-content .mat-form-field-weekpicker-datepicker-widget .mat-calendar-body td button.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.04);
}
.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-close-button,
.cdk-overlay-pane.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-close-button {
  box-shadow: none;
  font-weight: 700;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.04);
}

mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field {
  padding-inline: 12px;
  line-height: 1;
  background-color: #fff;
  transition: background-color 0.1s ease-out;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.16);
  border-width: 1px;
  transition: border-color 0.15s ease-out;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  padding-block: 12px;
  min-height: unset;
  width: unset;
}

mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--focused .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--focused .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--focused .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.4);
}

mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--disabled .mat-mdc-form-field-flex .mdc-notched-outline::after {
  position: absolute;
  inset: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.08);
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--disabled .mat-mdc-form-field-flex .mat-mdc-form-field-infix input[disabled] {
  color: rgba(0, 0, 0, 0.4);
}

mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea::placeholder,
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix input::placeholder {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  white-space: normal;
}
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea[disabled]::placeholder,
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix input[disabled]::placeholder {
  color: rgba(0, 0, 0, 0.16);
}

mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field {
  max-width: var(--input-max-width, unset);
}

mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex input,
textarea {
  text-align: var(--input-text-align, left);
}

.mat-mdc-form-field:not(.mat-form-field-invalid) .mat-mdc-input-element {
  caret-color: #0f172a;
}

input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=search i]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

mat-form-field.mat-mdc-form-field.mat-mdc-form-field-has-icon-prefix .mat-mdc-form-field-icon-prefix {
  padding: 0;
}
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-has-icon-prefix .mat-mdc-form-field-icon-prefix mat-icon.mat-icon {
  display: inline-flex;
  align-items: center;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-form-field-icon-suffix {
  --mat-icon-button-touch-target-display: none;
  padding-inline-start: 8px !important;
  margin-inline-end: -4px;
}
.mat-mdc-form-field-has-icon-suffix .mat-mdc-form-field-icon-suffix .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 32px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 4px;
}
.mat-mdc-form-field-has-icon-suffix .mat-mdc-form-field-icon-suffix .mdc-icon-button.mat-mdc-icon-button {
  border-radius: 2px;
  --mdc-icon-button-icon-size: 24px;
}
.mat-mdc-form-field-has-icon-suffix .mat-mdc-form-field-icon-suffix .mdc-icon-button.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: inherit;
}
.mat-mdc-form-field-has-icon-suffix .mat-mdc-form-field-icon-suffix .mdc-icon-button.mat-mdc-icon-button mat-icon.mat-icon svg {
  --mdc-icon-button-icon-size: 20px;
  width: var(--mdc-icon-button-icon-size) !important;
  height: var(--mdc-icon-button-icon-size) !important;
}
.mat-mdc-form-field-has-icon-suffix .mat-mdc-form-field-icon-suffix .mdc-icon-button.mat-mdc-icon-button mat-spinner.mat-mdc-progress-spinner {
  --mdc-icon-button-icon-size: 20px;
  width: var(--mdc-icon-button-icon-size) !important;
  height: var(--mdc-icon-button-icon-size) !important;
  transform: translateX(2px);
}

.mat-mdc-form-field-icon-suffix .mat-mdc-icon-button {
  color: rgba(0, 0, 0, 0.4);
}

.mat-form-field-disabled .mat-mdc-form-field-text-suffix,
.mat-form-field-disabled .mat-mdc-form-field-icon-suffix {
  pointer-events: none;
  opacity: 0.4;
}

.mat-mdc-form-field.mat-form-field-appearance-outline.jbd-form-file-wrapper ngx-dropzone.jbd-form-file-dropzone {
  background: transparent;
  border: none;
  height: 120px;
}

mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline.jbd-form-file-wrapper .mat-mdc-form-field-flex {
  padding: 0;
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline.jbd-form-file-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline.jbd-form-file-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline.jbd-form-file-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  border-style: dashed;
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline.jbd-form-file-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
  border-right-style: none;
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline.jbd-form-file-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch {
  border-left-style: none;
  border-right-style: none;
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline.jbd-form-file-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  border-left-style: none;
}
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline.jbd-form-file-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  padding-block: 0;
}

.form-group mat-label,
.form-group .mat-label {
  display: block;
  margin-bottom: 8px;
}

mat-radio-button.mat-mdc-radio-button {
  --mdc-radio-state-layer-size: 32px;
  --mat-radio-touch-target-display: none;
}
mat-radio-button.mat-mdc-radio-button .mdc-radio__background {
  border-radius: 50%;
  background-color: #fff;
}
mat-radio-button.mat-mdc-radio-button .mdc-radio__outer-circle {
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.16) !important;
  --mdc-radio-unselected-focus-icon-color: var(
    --mdc-radio-unselected-icon-color
  );
  --mdc-radio-unselected-hover-icon-color: var(
    --mdc-radio-unselected-icon-color
  );
  --mdc-radio-unselected-pressed-icon-color: var(
    --mdc-radio-unselected-icon-color
  );
  --mdc-radio-selected-focus-icon-color: var(
    --mdc-radio-unselected-icon-color
  );
  --mdc-radio-selected-hover-icon-color: var(
    --mdc-radio-unselected-icon-color
  );
  --mdc-radio-selected-icon-color: var(--mdc-radio-unselected-icon-color);
  --mdc-radio-selected-pressed-icon-color: var(
    --mdc-radio-unselected-icon-color
  );
}
mat-radio-button.mat-mdc-radio-button .mdc-radio {
  line-height: 1;
  padding: 12px;
}
mat-radio-button.mat-mdc-radio-button .mdc-radio,
mat-radio-button.mat-mdc-radio-button .mdc-radio__outer-circle,
mat-radio-button.mat-mdc-radio-button .mdc-radio__inner-circle,
mat-radio-button.mat-mdc-radio-button .mdc-radio__background {
  width: 16px !important;
  height: 16px !important;
}
mat-radio-button.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus) ~ .mdc-radio__background::before,
mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:not([disabled]):focus ~ .mdc-radio__background::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
}
mat-radio-button.mat-mdc-radio-button .mdc-radio__inner-circle,
mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
}
mat-radio-button.mat-mdc-radio-button .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
  transform: translate(-50%, -50%) scale(0.4);
}
mat-radio-button.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #009489;
  --mdc-radio-selected-icon-color: #009489;
  --mat-radio-checked-ripple-color: #009489;
}

.mat-mdc-radio-group mat-radio-button.mat-mdc-radio-button {
  min-width: 100px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.mat-mdc-radio-group mat-radio-button.mat-mdc-radio-button .mdc-form-field {
  display: flex;
  align-items: flex-start;
}
.mat-mdc-radio-group mat-radio-button.mat-mdc-radio-button .mdc-form-field label[for^=mat-radio] {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 8px 8px calc(40px + 4px);
  flex: 1;
  font-size: 0.875rem;
  margin-left: -40px;
}

.mat-mdc-radio-group {
  gap: 4px;
}
.mat-mdc-radio-group.is-stacked {
  flex-direction: column !important;
}
@media (min-width: 600px) {
  .mat-mdc-radio-group:not(.is-stacked) {
    gap: 12px;
  }
}

.mat-mdc-radio-group:not(.inline-label-can-wrap) label {
  white-space: nowrap;
}

.mdc-radio.mdc-radio--disabled,
.mdc-radio.mdc-radio--disabled + label[for^=mat-radio] {
  cursor: not-allowed !important;
}

jbd-ui-form-select .mat-mdc-select-arrow-wrapper {
  opacity: 0;
  width: 0;
  height: 0;
}
jbd-ui-form-select .mat-mdc-form-field-icon-suffix {
  align-self: flex-start;
  margin-top: 8px;
}
jbd-ui-form-select .mat-mdc-form-field-icon-suffix .mat-select-custom-arrow svg {
  transform: scale(0.85);
  transition: transform 0.2s ease;
}
jbd-ui-form-select .mat-mdc-select-placeholder {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

jbd-ui-form-select .mat-select-list-is-open mat-form-field.mat-mdc-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
jbd-ui-form-select .mat-select-list-is-open mat-form-field.mat-mdc-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
jbd-ui-form-select .mat-select-list-is-open mat-form-field.mat-mdc-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open {
  background: linear-gradient(#fff 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  /* enhances overflow Scrolling on ios */
  -webkit-overflow-scrolling: touch;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-top-width: 0;
  box-shadow: none;
  padding-block: 0;
}
.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open .mat-mdc-optgroup .mat-mdc-optgroup-label .mdc-list-item__primary-text {
  --mdc-list-list-item-label-text-weight: 600;
  --mdc-list-list-item-label-text-size: 0.875rem;
  color: rgba(0, 0, 0, 0.4);
}
.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open .mat-mdc-option.mat-mdc-option-active {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open .mat-mdc-option.mat-mdc-option-active:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open .mat-mdc-option .mdc-list-item__primary-text {
  font-size: 1rem;
  font-weight: 600;
  color: #0f172a;
}
.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open .mat-mdc-option.mdc-list-item--disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open .mat-mdc-option.mdc-list-item--disabled * {
  pointer-events: none;
}

jbd-ui-form-select .mat-select-list-is-open .mat-mdc-form-field-icon-suffix .mat-select-custom-arrow svg {
  transform: scale(0.85) rotate(-180deg);
}

.form-group .mat-form-field-stepper {
  position: relative;
  padding: 2px 4px;
  border-color: rgba(0, 0, 0, 0.3);
  transition: border-color 0.15s ease-out;
}
.form-group .mat-form-field-stepper:focus-within {
  border-color: rgba(0, 0, 0, 0.4);
}
.form-group .mat-form-field-stepper-inline-label {
  color: #0f172a;
}
.form-group .mat-form-field-stepper input.form-control {
  position: absolute;
  inset: 0;
  z-index: -1;
  opacity: 0;
}

.form-group .mat-mdc-form-field-hint-wrapper,
.form-group .mat-mdc-form-field-error-wrapper {
  padding-inline: 0;
}
.form-group .mat-mdc-form-field-bottom-align::before {
  content: unset;
}

.form-group.is-invalid mat-label,
.form-group.is-invalid label.mat-label {
  color: #dc2626;
}

.form-group.is-invalid mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field {
  background-color: #fef2f2;
}
.form-group.is-invalid mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
.form-group.is-invalid mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
.form-group.is-invalid mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #dc2626;
}

.form-group.is-invalid mat-form-field.mat-mdc-form-field .mat-mdc-form-field-icon-suffix .mat-mdc-icon-button {
  color: #f87171;
}

.form-group.is-invalid mat-checkbox.mat-mdc-checkbox {
  background-color: #fef2f2;
}
.form-group.is-invalid mat-checkbox.mat-mdc-checkbox .mdc-checkbox {
  --mdc-checkbox-unselected-icon-color: #dc2626;
}
.form-group.is-invalid mat-checkbox.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  background-color: #fff;
}
.form-group.is-invalid mat-checkbox.mat-mdc-checkbox.mat-mdc-checkbox-checked {
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-focus-icon-color: var(
    --mdc-checkbox-selected-icon-color
  );
  --mdc-checkbox-selected-hover-icon-color: var(
    --mdc-checkbox-selected-icon-color
  );
  --mdc-checkbox-selected-pressed-icon-color: var(
    --mdc-checkbox-selected-icon-color
  );
}

.form-group.is-invalid .jbd-form-file-wrapper ngx-dropzone.jbd-form-file-dropzone ngx-dropzone-label > span {
  color: #dc2626;
}

mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker {
  position: relative;
}
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker.has-limited-width {
  width: 280px;
}
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker-displayed-date {
  color: #0f172a;
}
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker input.form-control {
  position: absolute;
  inset: 0;
  z-index: -1;
  opacity: 0;
}
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker .mat-mdc-text-field-wrapper.mdc-text-field {
  padding-inline-start: 4px;
}
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-infix {
  padding-block: 4px !important;
}
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker .mat-mdc-form-field-icon-suffix jbd-ui-icon-button {
  --mat-icon-button-touch-target-display: none;
}
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker .mat-mdc-form-field-icon-suffix jbd-ui-icon-button .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 32px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 4px;
}
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker .mat-mdc-form-field-icon-suffix jbd-ui-icon-button mat-icon.mat-icon svg {
  --mdc-icon-button-icon-size: 16px;
  width: var(--mdc-icon-button-icon-size) !important;
  height: auto;
}

.form-group.mat-datepicker-is-open mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
.form-group.mat-datepicker-is-open mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
.form-group.mat-datepicker-is-open mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing,
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker:focus-within .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker:focus-within .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch,
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker:focus-within .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.4);
}

.mat-mdc-menu-panel.sidenav-user-actions-dropdown {
  width: calc(250px - 2 * 16px) !important;
  max-width: none !important;
}

.mat-mdc-tab-nav-bar .mat-mdc-tab-list .mat-mdc-tab-links .mat-mdc-tab-link,
.mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab-links .mat-mdc-tab-link {
  min-width: 160px;
  --mdc-typography-button-font-weight: 600;
  --mat-tab-header-label-text-tracking: 1;
}
.mat-mdc-tab-nav-bar .mat-mdc-tab-list .mat-mdc-tab-links .mat-mdc-tab-link .mdc-tab__content,
.mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab-links .mat-mdc-tab-link .mdc-tab__content {
  height: unset;
}
.mat-mdc-tab-nav-bar .mat-mdc-tab-list .mat-mdc-tab-links .mat-mdc-tab-link .mdc-tab__content .mdc-tab__text-label,
.mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab-links .mat-mdc-tab-link .mdc-tab__content .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.4);
}
.mat-mdc-tab-nav-bar .mat-mdc-tab-list .mat-mdc-tab-links .mat-mdc-tab-link.is-active .mdc-tab__content .mdc-tab__text-label,
.mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab-links .mat-mdc-tab-link.is-active .mdc-tab__content .mdc-tab__text-label {
  color: #0f172a;
}
.mat-mdc-tab-nav-bar .mat-mdc-tab-list .mdc-tab-indicator .mdc-tab-indicator__content.mdc-tab-indicator__content--underline,
.mat-mdc-tab-header .mat-mdc-tab-list .mdc-tab-indicator .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
  border-top-width: 3px;
  border-radius: 1.5px;
}

mat-toolbar.mat-toolbar {
  height: 56px;
  background-color: #fff;
}

jbd-ui-loader .mat-progress-spinner circle {
  stroke-linecap: round;
}

.mat-mdc-menu-trigger.is-open {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-mdc-menu-trigger.is-open:is(jbd-ui-icon-button) {
  border-radius: 50%;
}

div.mat-mdc-menu-panel {
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.16);
  box-shadow: none !important;
  min-height: 48px;
}
div.mat-mdc-menu-panel .mat-mdc-menu-content:not(:empty) {
  padding: 0;
}
div.mat-mdc-menu-panel .mat-mdc-menu-item {
  --mat-menu-item-label-text-weight: 600;
}

jbd-ui-stepper-nav nav:not(.stepper-mobile-nav) .stepper-nav-button[disabled] {
  cursor: not-allowed;
  pointer-events: unset;
}
jbd-ui-stepper-nav nav:not(.stepper-mobile-nav) .stepper-nav-button[disabled]:hover .mat-mdc-button-persistent-ripple {
  opacity: 0;
}
jbd-ui-stepper-nav nav:not(.stepper-mobile-nav) .stepper-nav-button[disabled] span.stepper-nav-button-label,
jbd-ui-stepper-nav nav:not(.stepper-mobile-nav) .stepper-nav-button[disabled] .step-circle-wrapper .step-circle.is-small span {
  opacity: 0.6;
}
jbd-ui-stepper-nav nav:not(.stepper-mobile-nav) .stepper-nav-button .mdc-button__label {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  z-index: -1;
}

jbd-ui-button-secondary button.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: transparent;
  --mdc-outlined-button-label-text-color: black !important;
  background-color: #fffdf2;
}
jbd-ui-button-secondary button.mat-mdc-outlined-button::before {
  border-color: rgba(247, 227, 131, 0.5) !important;
}

jbd-ui-button-primary .mat-mdc-unelevated-button.mat-primary[disabled] {
  --mdc-filled-button-container-color: #f7e383 !important;
  --mdc-filled-button-disabled-container-color: var(
    --mdc-filled-button-container-color
  );
  --mdc-filled-button-label-text-color: black !important;
  --mdc-filled-button-disabled-label-text-color: var(
    --mdc-filled-button-label-text-color
  );
}

jbd-ui-button-secondary .mat-mdc-outlined-button.mat-primary[disabled] {
  --mdc-filled-button-label-text-color: #f7e383;
}

jbd-ui-button-secondary jbd-ui-button-inner mat-progress-spinner.mat-mdc-progress-spinner,
jbd-ui-button-primary jbd-ui-button-inner mat-progress-spinner.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: black;
}

.mat-mdc-tab-nav-bar .mat-mdc-tab-list .mdc-tab-indicator .mdc-tab-indicator__content.mdc-tab-indicator__content--underline,
.mat-mdc-tab-header .mat-mdc-tab-list .mdc-tab-indicator .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
  border-top-color: #d8b407;
}

jbd-app-company-page jbd-ui-no-content p,
jbd-app-company-location-redirect jbd-ui-no-content p {
  width: clamp(240px, 100%, 320px) !important;
}

jbd-ui-stepper-nav > nav.stepper-desktop-nav > .stepper-nav-button.is-active {
  color: #d8b407 !important;
}
jbd-ui-stepper-nav > nav.stepper-desktop-nav > .stepper-nav-button .step-circle-wrapper > .step-circle.is-small:not(.is-valid) {
  background-color: #fefae5;
  color: #d8b407;
}
jbd-ui-stepper-nav > nav.stepper-mobile-nav .stepper-spinner-wrapper:before {
  border-color: #fefae5;
}
jbd-ui-stepper-nav > nav.stepper-mobile-nav .stepper-text-value {
  color: #d8b407;
}

.table-details-toggle-btn .mat-icon {
  transform: rotate(0deg);
  transition: transform 0.25s ease;
}
.table-details-toggle-btn.is-expanded .mat-icon {
  transform: rotate(-180deg);
}

:root {
  --fc-border-color: rgba(0, 0, 0, 0.08) !important;
  --fc-today-bg-color: transparent !important;
  --fc-highlight-color: transparent !important;
}

jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] thead[role=rowgroup] tr[role=presentation] th[role=presentation] {
  border: none;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] thead[role=rowgroup] tr[role=presentation] th[role=presentation] th[role=columnheader] {
  border: none;
  padding-block-end: 8px;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] thead[role=rowgroup] tr[role=presentation] th[role=presentation] th[role=columnheader] a {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 0.875rem;
}

jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] tbody[role=rowgroup] tr[role=presentation] td[role=presentation] {
  border: 2px solid var(--fc-border-color);
  border-radius: 4px;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] tbody[role=rowgroup] tr[role=presentation] td[role=presentation] table[role=presentation] tbody[role=presentation] tr[role=row]:has(.fc-day-today) {
  background-color: rgba(0, 0, 0, 0.04);
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] tbody[role=rowgroup] tr[role=presentation] td[role=presentation] table[role=presentation] tbody[role=presentation] tr[role=row]:has(.fc-daygrid-day):has(.fc-daygrid-event-harness) {
  cursor: pointer;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] tbody[role=rowgroup] tr[role=presentation] td[role=presentation] table[role=presentation] tbody[role=presentation] tr[role=row] td[role=gridcell] {
  padding: 8px;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] tbody[role=rowgroup] tr[role=presentation] td[role=presentation] table[role=presentation] tbody[role=presentation] tr[role=row] td[role=gridcell] .fc-daygrid-day-top a {
  color: #0f172a;
  font-weight: 600;
  padding: 0;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] tbody[role=rowgroup] tr[role=presentation] td[role=presentation] table[role=presentation] tbody[role=presentation] tr[role=row] td[role=gridcell] .fc-daygrid-day-events .fc-event {
  margin: 0;
  margin-top: 8px;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] tbody[role=rowgroup] tr[role=presentation] td[role=presentation] table[role=presentation] tbody[role=presentation] tr[role=row] td[role=gridcell] .fc-daygrid-day-events,
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] tbody[role=rowgroup] tr[role=presentation] td[role=presentation] table[role=presentation] tbody[role=presentation] tr[role=row] td[role=gridcell] .fc-daygrid-day-bottom {
  margin: 0 !important;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] tbody[role=rowgroup] tr[role=presentation] td[role=presentation] table[role=presentation] tbody[role=presentation] tr[role=row] td[role=gridcell].fc-day-other .fc-daygrid-day-top {
  opacity: 0.4;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] tbody[role=rowgroup] tr[role=presentation] td[role=presentation] table[role=presentation] tbody[role=presentation] tr[role=row] td[role=gridcell] a.fc-daygrid-week-number {
  padding: 3px 8px;
  border-radius: 8px;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0.25px;
  color: #0f172a;
  background-color: rgba(0, 0, 0, 0.08);
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-dayGridMonth-view table[role=grid] tbody[role=rowgroup] tr[role=presentation] td[role=presentation] table[role=presentation] tbody[role=presentation] tr[role=row] td[role=gridcell].fc-day-today .fc-daygrid-day-top a::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  border-radius: 50%;
  background-color: #a0ded9;
  width: 28px;
  aspect-ratio: 1;
  z-index: -1;
}

jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-header-toolbar.fc-toolbar {
  display: none;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard table[role=grid] {
  border: none;
}

jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list {
  border: none;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day:not(.fc-day-mon) {
  display: none;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day:not(.fc-day-mon) + tr.fc-event {
  display: none;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon {
  display: block;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon + tr.fc-event {
  display: block;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border: 2px solid var(--fc-border-color) !important;
  border-bottom: none !important;
  overflow: hidden;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon th[scope=colgroup] {
  border: none !important;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon + tr.fc-event {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: 2px solid var(--fc-border-color) !important;
  border-top: none !important;
  /*& td {
    border: none !important;
  }*/
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon th[scope=colgroup] {
  display: block;
  text-align: left;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon th[scope=colgroup] .fc-list-day-cushion {
  background-color: #fff;
  padding: 12px 12px 0;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon th[scope=colgroup] .fc-list-day-cushion jbd-ui-chip {
  transform: translateY(-2px);
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon th[scope=colgroup] .fc-list-day-cushion a {
  font-weight: 400;
  color: #0f172a;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon + tr.fc-event {
  display: block;
  text-align: left;
  background-color: #fff;
  padding: 8px 12px 12px 12px;
  margin-bottom: 4px;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon + tr.fc-event td.fc-list-event-title {
  display: block;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-view.fc-list .fc-list-table .fc-list-day.fc-day.fc-day-mon + tr.fc-event td.fc-list-event-graphic {
  display: none;
}

.jbd-calendar-event .event-icon {
  width: 16px;
  aspect-ratio: 1;
  border-radius: 2px;
  background-color: #fff;
  position: relative;
}
.jbd-calendar-event .event-icon mat-icon.mat-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.jbd-calendar-event .event-icon mat-icon.mat-icon svg {
  width: 12px !important;
  height: inherit;
}

.fc-daygrid-event.jbd-calendar-event {
  padding: 4px;
}
.fc-daygrid-event.jbd-calendar-event span {
  line-height: 1;
}

.fc-daygrid-event.jbd-calendar-event.is-vacation {
  background-color: #fff4bf;
  border: none;
}
.fc-daygrid-event.jbd-calendar-event.is-vacation .event-icon {
  background-color: #fefae5;
}
.fc-daygrid-event.jbd-calendar-event.is-vacation .event-icon mat-icon.mat-icon {
  color: #f0c808;
}
.fc-daygrid-event.jbd-calendar-event.is-vacation span {
  color: #0f172a;
}
.fc-daygrid-event.jbd-calendar-event.is-booked {
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.08);
}
.fc-daygrid-event.jbd-calendar-event.is-booked .event-icon {
  background-color: #f5f7f7;
}
.fc-daygrid-event.jbd-calendar-event.is-booked .event-icon mat-icon.mat-icon {
  color: rgba(0, 0, 0, 0.4);
}
.fc-daygrid-event.jbd-calendar-event.is-booked span {
  color: rgba(0, 0, 0, 0.6);
}
.fc-daygrid-event.jbd-calendar-event.is-booked span.period {
  color: rgba(0, 0, 0, 0.4);
}
.fc-daygrid-event.jbd-calendar-event.is-suggested, .fc-daygrid-event.jbd-calendar-event.is-accepted, .fc-daygrid-event.jbd-calendar-event.is-sent, .fc-daygrid-event.jbd-calendar-event.is-received {
  background-color: #fff;
  border: 2px solid #2e5582;
}
.fc-daygrid-event.jbd-calendar-event.is-suggested .event-icon, .fc-daygrid-event.jbd-calendar-event.is-accepted .event-icon, .fc-daygrid-event.jbd-calendar-event.is-sent .event-icon, .fc-daygrid-event.jbd-calendar-event.is-received .event-icon {
  background-color: #eef6ff;
}
.fc-daygrid-event.jbd-calendar-event.is-suggested .event-icon mat-icon.mat-icon, .fc-daygrid-event.jbd-calendar-event.is-accepted .event-icon mat-icon.mat-icon, .fc-daygrid-event.jbd-calendar-event.is-sent .event-icon mat-icon.mat-icon, .fc-daygrid-event.jbd-calendar-event.is-received .event-icon mat-icon.mat-icon {
  color: #2e5582;
}
.fc-daygrid-event.jbd-calendar-event.is-suggested span, .fc-daygrid-event.jbd-calendar-event.is-accepted span, .fc-daygrid-event.jbd-calendar-event.is-sent span, .fc-daygrid-event.jbd-calendar-event.is-received span {
  color: #0f172a;
}
.fc-daygrid-event.jbd-calendar-event.is-placed, .fc-daygrid-event.jbd-calendar-event.is-running, .fc-daygrid-event.jbd-calendar-event.is-finished {
  background-color: #2e5582;
  border: none;
}
.fc-daygrid-event.jbd-calendar-event.is-placed .event-icon, .fc-daygrid-event.jbd-calendar-event.is-running .event-icon, .fc-daygrid-event.jbd-calendar-event.is-finished .event-icon {
  background-color: #f0fdf4;
}
.fc-daygrid-event.jbd-calendar-event.is-placed .event-icon mat-icon.mat-icon, .fc-daygrid-event.jbd-calendar-event.is-running .event-icon mat-icon.mat-icon, .fc-daygrid-event.jbd-calendar-event.is-finished .event-icon mat-icon.mat-icon {
  color: #16a34a;
}
.fc-daygrid-event.jbd-calendar-event.is-placed span, .fc-daygrid-event.jbd-calendar-event.is-running span, .fc-daygrid-event.jbd-calendar-event.is-finished span {
  color: #fff;
}

.fc-list-event.jbd-calendar-event td.fc-list-event-title {
  border-radius: 4px;
  padding: 4px;
}
.fc-list-event.jbd-calendar-event td.fc-list-event-title span {
  line-height: 1;
}

.fc-list-event.jbd-calendar-event.is-vacation td.fc-list-event-title {
  background-color: #fff4bf;
  border: none;
}
.fc-list-event.jbd-calendar-event.is-vacation td.fc-list-event-title .event-icon {
  background-color: #fefae5;
}
.fc-list-event.jbd-calendar-event.is-vacation td.fc-list-event-title .event-icon mat-icon.mat-icon {
  color: #f0c808;
}
.fc-list-event.jbd-calendar-event.is-vacation td.fc-list-event-title span {
  color: #0f172a;
}
.fc-list-event.jbd-calendar-event.is-booked td.fc-list-event-title {
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.08);
}
.fc-list-event.jbd-calendar-event.is-booked td.fc-list-event-title .event-icon {
  background-color: #f5f7f7;
}
.fc-list-event.jbd-calendar-event.is-booked td.fc-list-event-title .event-icon mat-icon.mat-icon {
  color: rgba(0, 0, 0, 0.4);
}
.fc-list-event.jbd-calendar-event.is-booked td.fc-list-event-title span {
  color: rgba(0, 0, 0, 0.6);
}
.fc-list-event.jbd-calendar-event.is-booked td.fc-list-event-title span.period {
  color: rgba(0, 0, 0, 0.4);
}
.fc-list-event.jbd-calendar-event.is-suggested td.fc-list-event-title, .fc-list-event.jbd-calendar-event.is-accepted td.fc-list-event-title, .fc-list-event.jbd-calendar-event.is-sent td.fc-list-event-title, .fc-list-event.jbd-calendar-event.is-received td.fc-list-event-title {
  background-color: #fff;
  border: 2px solid #2e5582;
}
.fc-list-event.jbd-calendar-event.is-suggested td.fc-list-event-title .event-icon, .fc-list-event.jbd-calendar-event.is-accepted td.fc-list-event-title .event-icon, .fc-list-event.jbd-calendar-event.is-sent td.fc-list-event-title .event-icon, .fc-list-event.jbd-calendar-event.is-received td.fc-list-event-title .event-icon {
  background-color: #eef6ff;
}
.fc-list-event.jbd-calendar-event.is-suggested td.fc-list-event-title .event-icon mat-icon.mat-icon, .fc-list-event.jbd-calendar-event.is-accepted td.fc-list-event-title .event-icon mat-icon.mat-icon, .fc-list-event.jbd-calendar-event.is-sent td.fc-list-event-title .event-icon mat-icon.mat-icon, .fc-list-event.jbd-calendar-event.is-received td.fc-list-event-title .event-icon mat-icon.mat-icon {
  color: #2e5582;
}
.fc-list-event.jbd-calendar-event.is-suggested td.fc-list-event-title span, .fc-list-event.jbd-calendar-event.is-accepted td.fc-list-event-title span, .fc-list-event.jbd-calendar-event.is-sent td.fc-list-event-title span, .fc-list-event.jbd-calendar-event.is-received td.fc-list-event-title span {
  color: #0f172a;
}
.fc-list-event.jbd-calendar-event.is-placed td.fc-list-event-title, .fc-list-event.jbd-calendar-event.is-running td.fc-list-event-title, .fc-list-event.jbd-calendar-event.is-finished td.fc-list-event-title {
  background-color: #2e5582;
  border: none;
}
.fc-list-event.jbd-calendar-event.is-placed td.fc-list-event-title .event-icon, .fc-list-event.jbd-calendar-event.is-running td.fc-list-event-title .event-icon, .fc-list-event.jbd-calendar-event.is-finished td.fc-list-event-title .event-icon {
  background-color: #f0fdf4;
}
.fc-list-event.jbd-calendar-event.is-placed td.fc-list-event-title .event-icon mat-icon.mat-icon, .fc-list-event.jbd-calendar-event.is-running td.fc-list-event-title .event-icon mat-icon.mat-icon, .fc-list-event.jbd-calendar-event.is-finished td.fc-list-event-title .event-icon mat-icon.mat-icon {
  color: #16a34a;
}
.fc-list-event.jbd-calendar-event.is-placed td.fc-list-event-title span, .fc-list-event.jbd-calendar-event.is-running td.fc-list-event-title span, .fc-list-event.jbd-calendar-event.is-finished td.fc-list-event-title span {
  color: #fff;
}

jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-list-empty {
  border: 2px solid var(--fc-border-color);
  border-radius: 4px;
  background-color: #fff;
}
jbd-app-company-location-booking-overview-calendar-widget full-calendar.fc.fc-theme-standard .fc-list-empty .fc-list-empty-cushion {
  margin: 0;
}

image-cropper {
  --cropper-overlay-color: transparent;
}
image-cropper .ngx-ic-cropper .ngx-ic-move {
  position: relative;
}
image-cropper .ngx-ic-cropper .ngx-ic-move::before {
  position: absolute;
  content: "";
  inset: 0;
  border: 20px solid rgba(0, 0, 0, 0.15);
}

jbd-app-navigation .mat-mdc-nav-list .mat-mdc-list-item,
jbd-app-navigation .mat-mdc-nav-list .mat-expansion-panel-header {
  height: 36px;
}
jbd-app-navigation .mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content {
  line-height: 1;
  padding-left: 8px;
}
jbd-app-navigation .mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content .mat-mdc-list-item-title {
  --mdc-list-list-item-label-text-line-height: 1.5;
  --mdc-list-list-item-label-text-tracking: 0;
}
jbd-app-navigation .mat-mdc-nav-list .mat-expansion-panel-header .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.4);
}
jbd-app-navigation .mat-mdc-nav-list .mat-expansion-panel-body {
  padding: 0;
}
jbd-app-navigation .mat-mdc-nav-list .mat-expansion-panel-body .mat-mdc-list-item .mdc-list-item__content {
  padding-left: 16px;
}

jbd-app-receipt-no-params i.no-token-state-icon mat-icon.mat-icon,
jbd-app-receipt-page i.cancelled-state-icon mat-icon.mat-icon {
  transform: translate(-50%, calc(-50% - 3px)) !important;
}