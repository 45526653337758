@use "core/src/stylesheets/base/colors" as color;

$calendar-default-gap-width: 8px;

$calendar-event-padding: 4px;
$calendar-event-state-icon-size: 16px;

:root {
  --fc-border-color: #{map-get(color.$monochrome, 300)} !important;
  --fc-today-bg-color: transparent !important;
  --fc-highlight-color: transparent !important;
}
