@use "variables" as calVar;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/utils/position" as *;

jbd-app-company-location-booking-overview-calendar-widget
  full-calendar.fc.fc-theme-standard
  .fc-dayGridMonth-view
  table[role="grid"] {
  tbody[role="rowgroup"] tr[role="presentation"] td[role="presentation"] {
    border: var.$border-width-default solid var(--fc-border-color);
    border-radius: var.$border-radius-sm;

    table[role="presentation"] tbody[role="presentation"] tr[role="row"] {
      // highlight active week
      &:has(.fc-day-today) {
        background-color: map-get(color.$monochrome, 200);
      }

      // show pointer on week with events
      &:has(.fc-daygrid-day):has(.fc-daygrid-event-harness) {
        cursor: pointer;
      }

      td[role="gridcell"] {
        padding: calVar.$calendar-default-gap-width;

        .fc-daygrid-day-top a {
          color: map-get(color.$monochrome, 900);
          font-weight: var.$font-weight-semibold;
          padding: 0;
        }

        .fc-daygrid-day-events .fc-event {
          margin: 0;
          margin-top: calVar.$calendar-default-gap-width;
        }

        .fc-daygrid-day-events,
        .fc-daygrid-day-bottom {
          margin: 0 !important;
        }

        // disable look'n'feel past- and future-month weekdays
        &.fc-day-other .fc-daygrid-day-top {
          opacity: 0.4;
        }

        // calendar week chip
        a.fc-daygrid-week-number {
          padding: var.$chip-inner-padding;
          border-radius: var.$chip-border-radius;
          font-size: var.$chip-font-size;
          font-weight: var.$chip-font-weight;
          letter-spacing: var.$chip-letter-spacing;
          color: map-get(color.$monochrome, 900);
          background-color: map-get(color.$monochrome, 300);
        }

        // highlight active day
        &.fc-day-today .fc-daygrid-day-top a::before {
          @include centered-absolute();
          content: "";
          border-radius: 50%;
          background-color: map-get(color.$accent, 300);
          width: 28px;
          aspect-ratio: 1;
          z-index: -1;
        }
      }
    }
  }
}
