@use "apps/partner/src/stylesheets/base/variables" as var;

image-cropper {
  --cropper-overlay-color: transparent;

  .ngx-ic-cropper {
    .ngx-ic-move {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        inset: 0;
        border: var.$image-cropper-border-width solid
          var.$image-cropper-border-color;
      }
    }
  }
}
