@use "variables";
@forward "thead";
@forward "tbody";

jbd-app-company-location-booking-overview-calendar-widget
  full-calendar.fc.fc-theme-standard {
  .fc-header-toolbar.fc-toolbar {
    display: none;
  }

  table[role="grid"] {
    border: none;
  }
}
